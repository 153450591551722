import { keyBy, uniqBy } from "lodash";
import { useEffect, useState } from "react";

export const ACTION_TYPES = {
  MANUAL_ENTRY: "MANUAL_ENTRY",
  MANUAL_ENTRY_PARTICIPANT: "MANUAL_ENTRY_PARTICIPANT",
  SLACK: "SLACK",
  TEAMS: "TEAMS",
  AUTOMATIC: "AUTOMATIC",
  MAGIC_LINK: "MAGIC_LINK"
};

export const ACTION_LIMIT_TYPES = {
  NONE: "NONE",
  HOUR: "HOUR",
  DAY: "DAY",
  MONTH: "MONTH",
  YEAR: "YEAR",
  EVER: "EVER"
};

export const APPLY_TYPE = {
  INSTANCE: {
    value: "INSTANCE",
    display: "Instance"
  },
  STATEFUL: {
    value: "STATEFUL",
    display: "State"
  }
};

const baseActions = [
  { id: ACTION_TYPES.MANUAL_ENTRY, value: "Manual Entry Admin" },
  {
    id: ACTION_TYPES.MANUAL_ENTRY_PARTICIPANT,
    value: "Manual Entry Participant"
  },
  {
    id: ACTION_TYPES.MAGIC_LINK,
    value: "Magic Link"
  }
];

const allActions = [
  ...baseActions,
  { id: ACTION_TYPES.SLACK, value: "Slack" },
  { id: ACTION_TYPES.TEAMS, value: "Teams" }
];

export const ACTION_TYPES_DICT = keyBy(allActions, "id");

export const getActionOptions = (slackChannels = [], teamsIntegration = null) => {
  const options = [...baseActions];
  if (slackChannels?.length) {
    options.push({ id: ACTION_TYPES.SLACK, value: "Slack" });
  }
  if (teamsIntegration) {
    options.push({ id: ACTION_TYPES.TEAMS, value: "Teams" });
  }
  return options;
};

export const useActionOptions = (slackChannels = [], teamsChannels = []) => {
  const [options, setOptions] = useState(baseActions);
  useEffect(() => {
    if (slackChannels?.length) {
      setOptions((currentState) => uniqBy([...currentState, { id: ACTION_TYPES.SLACK, value: "Slack" }], "id"));
    }
    if (teamsChannels?.length) {
      setOptions((currentState) => uniqBy([...currentState, { id: ACTION_TYPES.TEAMS, value: "MS Teams" }], "id"));
    }
  }, [slackChannels, teamsChannels]);
  return options;
};

export const EVENT_OPTIONS = [
  { id: "MESSAGE", name: "Channel Post" },
  { id: "REACTION", name: "Reaction" },
  { id: "REPLY", name: "Reply" }
];

export const getLimitDisplay = (limit) => {
  switch (true) {
    case limit !== ACTION_LIMIT_TYPES.EVER && limit !== ACTION_LIMIT_TYPES.NONE:
      return `1 per ${limit.toLowerCase()}`;
    case limit === ACTION_LIMIT_TYPES.EVER:
      return `Once ${limit.toLowerCase()}`;
    default:
      return limit?.toLowerCase();
  }
};

export const getApprovalDisplay = (approvalRequired) =>
  approvalRequired ? "Requires approval" : "No approval Required";
