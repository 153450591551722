import { gql } from "@apollo/client";

export const ADD_ATTRIBUTE = gql`
  mutation addAttribute($data: AttributeInput!) {
    addAttribute(data: $data) {
      success
      message
    }
  }
`;

export const SAVE_CONNECTION_ATTRIBUTE = gql`
  mutation saveConnectionAttribute($data: ConnectionAttributeInput!) {
    saveConnectionAttribute(data: $data) {
      success
      message
    }
  }
`;

export const GET_CONNECTION_ATTRIBUTES = gql`
  query connectionAttributes($projectId: String!) {
    connectionAttributes(projectId: $projectId) {
      id
      sideAName
      sideAImage
      sideBName
      sideBImage
      sides {
        id
        name
        image
        side
      }
    }
  }
`;
export const GET_ATTRIBUTES = gql`
  query attributes($projectId: String!) {
    attributes(projectId: $projectId) {
      id
      type
      name
      value
      iconName
      iconUrl
      active
      projectId
      createdAt
      displayLocation
      attributeValues {
        id
        name
        iconName
        iconUrl
        index
        active
      }
      connection {
        id
        sideAName
        sideBImage
        sides {
          id
          name
          image
          side
        }
      }
    }
  }
`;

export const GET_ATTRIBUTE_BY_ID = gql`
  query GetAttributeById($id: String!) {
    attributeById(id: $id) {
      id
      type
      name
      value
      iconName
      iconUrl
      active
      projectId
      createdAt
      displayLocation
      attributeValues {
        id
        name
        iconName
        iconUrl
        index
        active
      }
    }
  }
`;

export const DELETE_ATTRIBUTE = gql`
  mutation deleteAttribute($id: String!) {
    deleteAttribute(id: $id) {
      success
      message
    }
  }
`;

export const DELETE_ATTRIBUTE_VALUE = gql`
  mutation deleteAttributeValue($id: String!) {
    deleteAttributeValue(id: $id) {
      success
      message
    }
  }
`;

export const GET_ATTRIBUTES_IN_USE = gql`
  query GET_ATTRIBUTES_IN_USE($projectId: String!) {
    attributesInUse(projectId: $projectId)
  }
`;

export const UPDATE_ATTRIBUTE_ORDER = gql`
  mutation updateAttributeOrder($data: UpdateListOrderArgs!) {
    updateAttributeOrder(data: $data) {
      success
      message
    }
  }
`;

export const UPDATE_USER_ATTRIBUTE_CONNECTION = gql`
  mutation updateUserAttributeConnection(
    $sourceUserId: String!
    $targetUserId: String!
    $connectionId: String!
    $targetUserSide: String!
  ) {
    updateUserAttributeConnection(
      sourceUserId: $sourceUserId
      targetUserId: $targetUserId
      connectionId: $connectionId
      targetUserSide: $targetUserSide
    ) {
      success
      message
    }
  }
`;

export const ACCEPT_USER_CONNECTION_REQUEST = gql`
  mutation acceptUserConnectionRequest($requestId: String!) {
    acceptUserConnectionRequest(requestId: $requestId) {
      success
      message
    }
  }
`;

export const DECLINE_USER_CONNECTION_REQUEST = gql`
  mutation declineUserConnectionRequest($requestId: String!) {
    declineUserConnectionRequest(requestId: $requestId) {
      success
      message
    }
  }
`;
