import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

// * Components
import { ActionFormButton } from "theme/StyledComponents";
import CustomActionTextDisplay from "../CustomActionTextDisplay";
import CustomDatePicker from "components/CustomDatePicker";
import CustomSelect from "components/CustomSelect";
import { UncontrolledInput } from "components/CustomInput/CustomInput";

// * Hooks, Helpers, Utils, etc
import { ATTRIBUTES_TYPES } from "features/Attributes/utils";
import { find, isEmpty, omit } from "lodash";
import { selectSpecificOperators } from "../../app/operators.slice";
import { useFormResetAttributeIdOperatorId, useSelectedAction, useSelectedAttributeId } from "../../hooks/form.hooks";
import { FeatureFlag } from "../../hocs/FeatureFlag/FeatureFlag";
import { FEATURE_FLAGS } from "../../hocs/FeatureFlag/utils";
import ActionExpirationForm from "./ActionExpirationForm";
import { DateTime } from "luxon";
import { calculateExpirationDate, checkDateExpired } from "../../utils/date.utils";

// * BulkLogActionForm Component emits valid selected or manual entry action to parent BulkLogActionModal
function BulkLogActionForm({
  resetForm,
  attributes,
  customActions,
  emitSelectedAction,
  emitActionIsValid,
  emitFormReset,
  emitExpired,
  emitCalculatedExpirationDate
}) {
  // * initialize component values and form values
  const [isValid, setIsValid] = useState(false);
  const [plusOperator, assignmentOperator, minusOperator] = useSelector(selectSpecificOperators(["+", "=", "-"]));
  const wholeNumberOperators = [plusOperator, minusOperator, assignmentOperator];
  const [selectedAttribute, setSelectedAttribute] = useState(attributes[0]);
  const actionOptions = useMemo(
    () => [
      {
        id: "MANUAL_ENTRY",
        name: "Manual Entry",
        value: "",
        operator: { id: "" },
        attribute: { id: "" },
        type: "MANUAL_ENTRY",
        customActionId: "MANUAL_ENTRY"
      },
      ...customActions
    ],
    [customActions]
  );
  const [selectedAction, setSelectedAction] = useState(actionOptions[0]);

  // * The Form
  const { register, watch, reset, getValues, setValue } = useForm({
    action: actionOptions[0].id,
    value: "",
    name: "",
    attributeId: attributes[0]?.id,
    expires: false,
    expirationPeriod: null,
    expirationPeriodAmount: null
  });

  // * initialize "watch" values
  // * note: "first render of watch will return undefined (unless default defined in form init or as 2nd param in watch())"
  const attributeId = watch("attributeId");
  const action = watch("action");
  const name = watch("name");
  const value = watch("value");
  const expires = watch("expires");
  const expirationPeriod = watch("expirationPeriod");
  const expirationPeriodAmount = watch("expirationPeriodAmount");
  const actionDate = watch("actionDate");

  // * Reset form values and emit null selection
  const handleReset = useCallback(() => {
    setSelectedAction(actionOptions[0]);
    setSelectedAttribute(attributes[0]);
    setIsValid(false);
    emitSelectedAction(null);
    reset();
    emitFormReset(true);
  }, [
    setSelectedAction,
    setSelectedAttribute,
    setIsValid,
    emitSelectedAction,
    reset,
    actionOptions,
    attributes,
    emitFormReset
  ]);

  // * reads resetForm value from parent Modal component's reset button
  useEffect(() => {
    if (resetForm) {
      handleReset();
    }
  }, [resetForm, handleReset]);

  const getAttributeDisplayValue = useCallback(
    (formData) => {
      if (selectedAttribute?.type === ATTRIBUTES_TYPES.WHOLE_NUMBER) {
        return formData.value;
      }
      return find(selectedAttribute?.attributeValues, (av) => av.id === formData.value)?.name;
    },
    [selectedAttribute?.type, selectedAttribute?.attributeValues]
  );

  // * setIsValid property for component and emit to Modal component for validation check
  useEffect(() => {
    const baseComparison = !action || action === "MANUAL_ENTRY" ? !!name?.trim() && !!value : true;
    if (!expires) {
      setIsValid(baseComparison);
      emitActionIsValid(baseComparison);
    } else {
      setIsValid(expires && expirationPeriodAmount && expirationPeriod && baseComparison);
      emitActionIsValid(expires && expirationPeriodAmount && expirationPeriod && baseComparison);
    }
  }, [
    action,
    name,
    value,
    attributeId,
    resetForm,
    isValid,
    emitActionIsValid,
    expires,
    expirationPeriodAmount,
    expirationPeriod
  ]);

  // * Custom Hooks For Action Selection and Form Reset
  useSelectedAttributeId(attributeId, attributes, setSelectedAttribute);
  useSelectedAction(action, actionOptions, setSelectedAction, attributes, setSelectedAttribute);
  useFormResetAttributeIdOperatorId(plusOperator, assignmentOperator, selectedAttribute, reset);

  useSelectedActionToSetExpiration(selectedAction, actionDate, emitCalculatedExpirationDate, emitExpired, setValue);
  useManualEntryActionToSetExpiration(
    expires,
    expirationPeriodAmount,
    expirationPeriod,
    selectedAction,
    actionDate,
    emitCalculatedExpirationDate,
    emitExpired,
    emitSelectedAction
  );

  const extractFormValuesFromAction = (action) => {
    return {
      value: action.value,
      operatorId: action.operator.id,
      attributeId: action.attribute.id,
      attributeName: action.attribute.name,
      name: action.name,
      type: action.type,
      customActionId: action.id
    };
  };

  // * read getValues() as formData and emit selectedAction to Modal component
  useEffect(() => {
    const formData = getValues();
    const base = !selectedAction ? formData : extractFormValuesFromAction(selectedAction);
    emitSelectedAction({
      action: {
        ...omit(base, ["action", "actionDate"]),
        actionDate: formData.actionDate,
        id: base?.action,
        attributeName: selectedAttribute?.name,
        attributeId: base.attributeId,
        displayValue: getAttributeDisplayValue(base),
        name: base.name,
        expires: formData.expires ?? false,
        type: base.type ?? "MANUAL_ENTRY", // values from the form do not have a "type" field so this is correctly set
        customActionId: base?.customActionId ?? "MANUAL_ENTRY" // same for customActionId
      }
    });
  }, [
    action,
    selectedAction,
    selectedAttribute,
    isValid,
    resetForm,
    name,
    value,
    emitSelectedAction,
    getValues,
    getAttributeDisplayValue
  ]);

  const handleExpirationValues = (values) => {
    if (values.expires) {
      setValue("expirationPeriod", values.period);
      setValue("expirationPeriodAmount", +values.periodAmount);
      setValue("expires", values.expires);
    } else {
      setValue("expires", false);
      emitExpired(false);
      emitCalculatedExpirationDate(null);
    }
  };

  return (
    <div>
      <form data-testid="ACTION_FORM">
        <div
          className="d-flex flex-row align-items-center gap-2"
          style={{ justifyContent: "start", grid: "initial", flexWrap: "nowrap" }}
        >
          <CustomDatePicker formRegister={register} name="actionDate" width="9rem" data-testid="BULK_LOG_DATE_PICKER" />
          <CustomSelect
            label="ACTION"
            formRegister={register}
            name="action"
            options={actionOptions}
            width="95rem"
            displayKey="name"
          />
          <strong className="mx-1">=</strong>
          {isEmpty(selectedAction) ? (
            <>
              <UncontrolledInput
                labelText="NAME"
                formRegister={register}
                name="name"
                maxWidth="8rem"
                testId="BULK_LOG_NAME_INPUT"
                className="uncontrolledName"
              />
              <CustomSelect
                label="ATTRIBUTE"
                formRegister={register}
                name="attributeId"
                options={attributes}
                width="9rem"
                displayKey="name"
              />
              {selectedAttribute?.type === ATTRIBUTES_TYPES.WHOLE_NUMBER ? (
                <CustomSelect
                  label="OPERATOR"
                  formRegister={register}
                  name="operatorId"
                  width="4rem"
                  options={wholeNumberOperators}
                />
              ) : (
                <CustomSelect
                  label="OPERATOR"
                  formRegister={register}
                  name="operatorId"
                  width="4rem"
                  options={[assignmentOperator]}
                />
              )}
              {selectedAttribute?.type === ATTRIBUTES_TYPES.WHOLE_NUMBER ? (
                <UncontrolledInput
                  labelText="VALUE"
                  formRegister={register}
                  name="value"
                  maxWidth="4rem"
                  type="number"
                  testId="BULK_LOG_VALUE_INPUT"
                  className="uncontrolledValue"
                />
              ) : (
                <CustomSelect
                  label="VALUE"
                  formRegister={register}
                  name="value"
                  displayKey="name"
                  options={selectedAttribute?.attributeValues}
                />
              )}
            </>
          ) : (
            <CustomActionTextDisplay action={selectedAction} />
          )}
          <ActionFormButton
            type="button"
            className="btn shadow border-0 btn-outline-primary"
            onClick={handleReset}
            buttonType="undo"
            disabled={!isValid}
            data-testid="BULK_LOG_ACTION_FORM_RESET"
          >
            <i className="fa fa-undo" />
          </ActionFormButton>
        </div>
        <div className="mt-3 d-flex justify-content-start w-100">
          <FeatureFlag featureName={FEATURE_FLAGS.EXPIRING_ACTIONS}>
            {isEmpty(selectedAction) && <ActionExpirationForm emitExpiration={handleExpirationValues} />}
          </FeatureFlag>
        </div>
      </form>
    </div>
  );
}

const useSelectedActionToSetExpiration = (
  selectedAction,
  actionDate,
  emitCalculatedExpirationDate,
  emitExpired,
  setValue
) => {
  useEffect(() => {
    if (
      selectedAction &&
      selectedAction?.id !== "MANUAL_ENTRY" &&
      selectedAction?.expirationPeriod &&
      selectedAction?.expirationPeriodAmount &&
      actionDate
    ) {
      setValue("expires", true);
      setValue("expirationPeriod", selectedAction.expirationPeriod);
      setValue("expirationPeriodAmount", +selectedAction.expirationPeriodAmount);
      setExpirationValues(
        +selectedAction.expirationPeriodAmount,
        selectedAction.expirationPeriod,
        actionDate,
        emitCalculatedExpirationDate,
        emitExpired
      );
    } else if (selectedAction && selectedAction?.id !== "MANUAL_ENTRY") {
      setValue("expires", false);
      emitExpired(false);
    }
  }, [selectedAction, setValue, actionDate, emitExpired, emitCalculatedExpirationDate]);
};

const useManualEntryActionToSetExpiration = (
  expires,
  expirationPeriodAmount,
  expirationPeriod,
  selectedAction,
  actionDate,
  emitCalculatedExpirationDate,
  emitExpired
) => {
  useEffect(() => {
    if (expirationPeriodAmount && expirationPeriod && !selectedAction) {
      setExpirationValues(
        expirationPeriodAmount,
        expirationPeriod,
        actionDate,
        emitCalculatedExpirationDate,
        emitExpired
      );
    }
  }, [
    expires,
    expirationPeriodAmount,
    expirationPeriod,
    actionDate,
    emitExpired,
    selectedAction,
    emitCalculatedExpirationDate
  ]);
};

const setExpirationValues = (
  expirationPeriodAmount,
  expirationPeriod,
  actionDate,
  emitCalculatedExpirationDate,
  emitExpired
) => {
  const calculatedExpirationDate = calculateExpirationDate(
    expirationPeriod,
    expirationPeriodAmount,
    actionDate ? DateTime.fromSQL(actionDate) : DateTime.now()
  );
  const alreadyExpired = checkDateExpired(calculatedExpirationDate);
  emitCalculatedExpirationDate(calculatedExpirationDate);
  emitExpired(alreadyExpired);
};

export default BulkLogActionForm;
