import { gql } from "@apollo/client";

// TODO, use refetch instead of getting return value here
export const CREATE_ACTION = gql`
  mutation CreateAction($data: ActionCreateInput!) {
    createAction(data: $data) {
      success
      message
    }
  }
`;

export const GET_AVAILABLE_ACTIONS = gql`
  query GET_AVAILABLE_ACTIONS($projectId: String!, $userId: String!) {
    availableActions(projectId: $projectId) {
      id
      name
      result
      type
      limit
      applyStrategy
      expirationPeriod
      expirationPeriodAmount
      statefulActionEnabled(userId: $userId)
      lastDone(userId: $userId)
      timesDone(userId: $userId)
      lastInstancePending(userId: $userId) {
        pending
        actionId
      }
    }
  }
`;

export const GET_ACTION_LOG = gql`
  query GET_ACTION_LOG($projectId: String!) {
    actionLog(projectId: $projectId) {
      id
      name
      result
      createdAt
      actionDate
      approvalStatus
      approvalDetails
      calculatedValue
      expired
      expirationDate
      dateApplied
      customAction {
        applyStrategy
      }
      creator
    }
  }
`;

export const GET_PROJECT_ACTION_LOG = gql`
  query GET_ACTION_LOG($projectId: String!) {
    projectActionLog(projectId: $projectId) {
      id
      name
      result
      calculatedValue
      dateApplied
      createdAt
      actionDate
      approvalStatus
      approvalDetails
      expirationDate
      createdBy
      expired
      result
      creator
      user {
        fullName
        email
      }
    }
  }
`;

export const GET_CUSTOM_ACTIONS = gql`
  query GET_CUSTOM_ACTIONS($projectId: String!) {
    getCustomActions(projectId: $projectId) {
      id
      name
      type
      value
      channelName
      channelId
      incomingEventType
      createdAt
      approvalRequired
      expirationPeriodAmount
      expirationPeriod
      limit
      displayValue
      applyStrategy
      result
      attribute {
        name
        id
      }
      operator {
        id
        value
      }
    }
  }
`;

export const SUBMIT_ACTION = gql`
  mutation submitAction($data: ActionSubmitInput!) {
    submitAction(data: $data) {
      success
      message
    }
  }
`;

export const SUBMIT_MAGIC_LINK = gql`
  mutation submitMagicLink($data: ActionSubmitInput!) {
    submitMagicLink(data: $data) {
      success
      message
    }
  }
`;

export const APPROVE_ACTION = gql`
  mutation approveAction($actionId: String!) {
    approveAction(actionId: $actionId) {
      success
      message
    }
  }
`;

export const REJECT_ACTION = gql`
  mutation rejectAction($actionId: String!, $accountId: String!) {
    rejectAction(actionId: $actionId, accountId: $accountId) {
      success
      message
    }
  }
`;

export const CREATE_CUSTOM_ACTION = gql`
  mutation createCustomAction($data: CustomActionUpsertInput!) {
    createCustomAction(data: $data) {
      success
      message
    }
  }
`;

export const DELETE_CUSTOM_ACTION = gql`
  mutation DELETE_CUSTOM_ACTION($id: String!) {
    deleteCustomAction(id: $id) {
      success
      message
    }
  }
`;

export const UPDATE_CUSTOM_ACTION_ORDER = gql`
  mutation updateCustomActionOrder($data: UpdateListOrderArgs!) {
    updateCustomActionOrder(data: $data) {
      success
      message
    }
  }
`;

export const CHECK_ACTION_WITHIN_TIME_LIMIT = gql`
  query checkIsActionWithinTimeLimit($customActionId: String!, $userId: String!) {
    checkIsActionWithinTimeLimit(customActionId: $customActionId, userId: $userId) {
      success
      message
    }
  }
`;

export const CREATE_BULK_LOG_ACTION = gql`
  mutation createBulkLogAction($data: BulkLogActionInput!) {
    createBulkLogAction(bulkLogActionInput: $data) {
      success
      message
    }
  }
`;
