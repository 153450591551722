import React, { memo, useCallback, useRef, useState } from "react";
import styled from "styled-components";
import { map, isEmpty, keyBy } from "lodash";
import { v4 as uuid } from "uuid";

import { Field } from "theme/StyledComponents";
import { useOutsideClick } from "hooks/dom.hooks";

const StyledRadio = styled("input").attrs({
  type: "radio"
})`
  display: none;
`;

const RadioList = styled("div").attrs({
  className: "card"
})`
  position: absolute;
  top: 2.5rem;
  opacity: 1;

  &:hover {
    opacity: 10;
    z-index: 1000;
  }

  z-index: 1000;
  min-width: 16rem;

  label {
    width: 100%;
    background: #ffffff;
    margin: 0;
    height: 2.5rem;
    display: flex;
    align-items: center;
    padding: 0 0.5rem;

    &:hover {
      background: #e2edff;
    }
  }
`;

function CustomSelectRadio({
  defaultValue,
  name,
  options = [],
  label,
  formRegister = () => ({}),
  displayKey,
  validation = {},
  handleSuccess = () => ({}),
  isAdmin = false,
  ...rest
}) {
  const optionsDict = keyBy(options, "id");
  const elementRef = useRef();
  const [showList, setShowList] = useState(false);

  const toggleList = useCallback(() => setShowList(!showList), [setShowList, showList]);
  const closeList = useCallback(() => setShowList(false), [setShowList]);

  const successPassthrough = () => {
    handleSuccess();
    closeList();
  };

  useOutsideClick(elementRef, closeList);
  const formRegisterProps = formRegister(name, { ...validation });
  const handleRadioChange = useCallback(
    (e) => {
      const newLabel = e.target.getAttribute("data-new");
      if (newLabel === "true" || newLabel === true) {
        console.info(newLabel);
      } else {
        formRegisterProps.onChange(e);
        closeList();
      }
    },
    [formRegisterProps, closeList]
  );
  return (
    <Field {...rest} width="12rem" height="2.5rem" ref={elementRef}>
      <div style={{ position: "relative" }}>
        <div
          data-testid={`radio-target-${rest.testId}`}
          id="radio-target"
          style={{
            minWidth: "10rem",
            display: "flex",
            overflow: "hidden"
          }}
          onClick={toggleList}
        >
          <span id="value">{optionsDict[defaultValue]?.name}</span>
          <span id="icon">{showList ? <i className="fa fa-angle-up" /> : <i className="fa fa-angle-down" />}</span>
        </div>
        <RadioList style={{ visibility: showList ? "visible" : "hidden" }}>
          {!isEmpty(options) &&
            map(options, (option) => {
              if (!isAdmin && option?.new) {
                // hides "new item" interactive row
                return null;
              }
              return (
                <span key={uuid()} style={{ display: "contents" }}>
                  <StyledRadio
                    id={`${option.id}-${rest?.id}`}
                    value={option.id}
                    {...formRegisterProps}
                    onChange={handleRadioChange}
                    data-new={option?.new || false}
                  />
                  <label htmlFor={`${option.id}-${rest?.id}`}>
                    {option[displayKey]({
                      handleSuccess: successPassthrough,
                      isAdmin
                    })}
                  </label>
                </span>
              );
            })}
        </RadioList>
      </div>
      <div className="label">{label}</div>
    </Field>
  );
}

export default memo(CustomSelectRadio);
