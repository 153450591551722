import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";

// * Hooks & Helpers
import { useHandleError, useHandleSuccess } from "hooks/api.hooks";
import { find, get, isEqual, map } from "lodash";

// * Utils
import { ATTRIBUTES_TYPES } from "features/Attributes/utils";
import { getSelectedAttribute, maybeJson } from "../utils/form.utils";
import { RULE_CONDITION_TYPES, RULE_CONDITION_VALUE_TYPES } from "../features/Rules/utils";

// * Slices
import { selectAttributeDictWithConnectionSides, selectAttributes } from "../features/Attributes/AttributesSlice";
import { selectOperatorsByType, selectSpecificOperators } from "../app/operators.slice";
import { setGlobalLoading } from "features/Common/CommonSlice";

export const useSelectedAttribute = (attributeId) => {
  const attributes = useSelector(selectAttributes);
  const attributeDict = useSelector(selectAttributeDictWithConnectionSides);

  const lookup = attributeId ?? attributes[0]?.id;
  const [selectedAttribute, setSelectedAttribute] = useState(attributeDict[lookup]);
  useEffect(() => {
    setSelectedAttribute(attributeDict[lookup]);
  }, [attributeId, attributeDict, lookup]);

  return selectedAttribute;
};

export const useHandleAttributeChange = (attributes, stateSetter) => {
  const handler = useCallback(
    (id) => {
      const _selectedAttribute = getSelectedAttribute(id, attributes);
      stateSetter(_selectedAttribute);
    },
    [attributes, stateSetter]
  );

  return handler;
};

export const useSubmit = ({
  mutation,
  variables,
  extractFormData = (formData) => formData,
  clearForm = () => ({}),
  successMessage,
  errorMessage,
  onSuccess = () => ({}),
  onFail = () => ({}),
  useVariables = false,
  useGlobalLoading = false,
  dataPath = ""
}) => {
  const dispatch = useDispatch();
  const handleError = useHandleError(errorMessage);
  const handleSuccess = useHandleSuccess(successMessage);
  const submit = useCallback(
    async (formData) => {
      const data = extractFormData(formData);
      if (useGlobalLoading) {
        dispatch(setGlobalLoading(true));
      }
      try {
        const response = await mutation({
          variables: useVariables
            ? variables
            : {
                data,
                onError: handleError
              }
        });
        const result = get(response, `data.${dataPath}`);

        if (!response.errors && result?.success) {
          const msg = maybeJson(result?.message);
          const data = maybeJson(result?.data);
          handleSuccess(typeof msg === "object" ? "" : msg);
          onSuccess(msg, data);
        } else if (!response.errors && !result.success) {
          onFail();
          handleError(result?.message);
        } else {
          onFail();
          handleError(result?.message);
        }
        clearForm();
      } catch (err) {
        handleError(err);
        console.log(err);
      } finally {
        dispatch(setGlobalLoading(false));
      }
    },
    [
      mutation,
      variables,
      clearForm,
      handleError,
      handleSuccess,
      useVariables,
      extractFormData,
      onSuccess,
      onFail,
      dataPath
    ]
  );

  return submit;
};

export const useShouldResetForm = (action, previousAction, formReset) =>
  useEffect(() => {
    if (!isEqual(previousAction, action)) {
      formReset(action);
    }
  }, [action, previousAction, formReset]);

// TODO(MB) clean this up
export const useIntendedInitialOperator = (entryId, attributeType, setFieldValue, field = "operatorId") => {
  const [assignmentOperator, deepEquality, plusOperator] = useSelector(selectSpecificOperators(["=", "===", "+"]));
  return useEffect(() => {
    switch (true) {
      case !!entryId:
        setFieldValue(field, entryId);
        break;
      case attributeType === ATTRIBUTES_TYPES.WHOLE_NUMBER:
        setFieldValue(field, entryId ?? plusOperator?.id);
        break;
      case attributeType === ATTRIBUTES_TYPES.VALUE_LIST:
        setFieldValue(field, assignmentOperator?.id);
        break;
      default:
        break;
    }
  }, [deepEquality?.id, assignmentOperator?.id, plusOperator?.id, setFieldValue, attributeType, entryId, field]);
};

export const useHasDirtyForm = (formIsDirty, formId, stateSetter) =>
  useEffect(() => {
    if (formIsDirty) {
      stateSetter((prev) => {
        prev.add(formId);
        return new Set(prev);
      });
    } else {
      stateSetter((prev) => {
        prev.delete(formId);
        return new Set(prev);
      });
    }
  }, [formIsDirty, stateSetter, formId]);

export const useWhenOperators = (attributeType, actor = "SELF", enhancedRuleConditionsEnabled = true) => {
  const [ops, setOps] = useState([]);
  const relationalOperators = useSelector(selectOperatorsByType("relational"));
  const historicalOperators = useSelector(selectOperatorsByType("historical"));
  const logicalOperators = useSelector(selectOperatorsByType("logical"));

  useEffect(() => {
    switch (attributeType) {
      case ATTRIBUTES_TYPES.VALUE_LIST:
        setOps(relationalOperators);
        break;

      case ATTRIBUTES_TYPES.WHOLE_NUMBER:
        if (!actor || (actor === "SELF" && enhancedRuleConditionsEnabled)) {
          setOps([...relationalOperators, ...historicalOperators]);
        } else {
          setOps(relationalOperators);
        }
        break;
      case ATTRIBUTES_TYPES.CONNECTION:
        setOps(logicalOperators); // is set, is not set
        break;
      default:
        break;
    }
  }, [actor, relationalOperators, attributeType, historicalOperators, enhancedRuleConditionsEnabled, logicalOperators]);
  return ops;
};

export const useThenOperators = (attributeType, canUserModifier = false, actor = "SELF") => {
  const [ops, setOps] = useState([]);
  const [assignmentOperator, times, divide, plus, minus] = useSelector(
    selectSpecificOperators(["=", "*", "/", "+", "-"])
  );

  useEffect(() => {
    switch (attributeType) {
      case ATTRIBUTES_TYPES.VALUE_LIST:
        setOps([assignmentOperator]);
        break;
      case ATTRIBUTES_TYPES.WHOLE_NUMBER:
        if (canUserModifier) {
          setOps([plus, minus, times, divide]);
        } else {
          setOps([assignmentOperator, plus, minus]);
        }
        break;
      default:
        break;
    }
  }, [plus, minus, actor, times, divide, assignmentOperator, attributeType, canUserModifier]);
  return ops;
};

export const useInitialOperatorForRuleCondition = (
  itemId,
  attributeType,
  ruleConditionType,
  setFieldValue,
  field = "operatorId",
  customAction = null
) => {
  const [assignmentOperator, deepEquality, plusOperator] = useSelector(selectSpecificOperators(["=", "===", "+"]));

  return useEffect(() => {
    switch (true) {
      case !!customAction:
        // customAction takes precedence
        setFieldValue(field, customAction?.operatorId);
        break;
      case !!itemId:
        // set the initial value if there is one
        setFieldValue(field, itemId);
        break;
      // ie) belt = 'green belt'
      case attributeType === ATTRIBUTES_TYPES.VALUE_LIST && ruleConditionType === RULE_CONDITION_TYPES.THEN:
        setFieldValue(field, assignmentOperator?.id);
        break;
      case attributeType === ATTRIBUTES_TYPES.VALUE_LIST && ruleConditionType === RULE_CONDITION_TYPES.WHEN:
        setFieldValue(field, deepEquality?.id);
        break;
      case attributeType === ATTRIBUTES_TYPES.WHOLE_NUMBER && ruleConditionType === RULE_CONDITION_TYPES.WHEN:
        setFieldValue(field, deepEquality?.id);
        break;
      case attributeType === ATTRIBUTES_TYPES.WHOLE_NUMBER && ruleConditionType === RULE_CONDITION_TYPES.THEN:
        setFieldValue(field, plusOperator?.id);
        break;
      default:
        break;
    }
  }, [
    itemId,
    field,
    setFieldValue,
    attributeType,
    ruleConditionType,
    assignmentOperator?.id,
    deepEquality?.id,
    plusOperator?.id,
    customAction
  ]);
};

export const useSetConditionValue = (selectedAttribute, setFieldValue, field, value) => {
  return useEffect(() => {
    switch (selectedAttribute?.type) {
      case ATTRIBUTES_TYPES.WHOLE_NUMBER:
        if (selectedAttribute?.valueType === RULE_CONDITION_VALUE_TYPES.VALUE) {
          const v = Number.parseInt(value);
          if (!Number.isInteger(v)) {
            // if it was an id, set to whole_number 0, else the current value
            setFieldValue(field, !value ? "0" : value);
          }
        }
        break;
      case ATTRIBUTES_TYPES.VALUE_LIST:
        // use current value if the original attribute is selected, else the first item in the list
        const valueToSet =
          !!value && map(get(selectedAttribute, "attributeValues", []), "id").includes(value)
            ? value
            : selectedAttribute?.attributeValues[0]?.id;

        setFieldValue(field, valueToSet);
        break;
      default:
        break;
    }
  }, [selectedAttribute, setFieldValue, field, value]);
};

// * Ensures correct attribute selected for Action Form.
export const useSelectedAttributeId = (attributeId, attributes, setSelectedAttribute, selectedAction) => {
  return useEffect(() => {
    if (!!attributeId) {
      const selectedAttr = find(attributes, (opt) => opt.id === attributeId);
      setSelectedAttribute(selectedAttr);
    }
  }, [attributeId, attributes, selectedAction]);
};

// * Ensures correct set action upon action or attribute selected change on Action Form.
export const useSelectedAction = (action, actionOptions, setSelectedAction, attributes, setSelectedAttribute) => {
  return useEffect(() => {
    if (!action || action === actionOptions[0].id) {
      setSelectedAction(null);
    } else {
      setSelectedAction(null);
      const tmp = find(actionOptions, (a) => a.id === action);
      setSelectedAction(tmp);
      const selectedAttr = find(attributes, (opt) => opt.id === tmp?.attribute?.id);
      setSelectedAttribute(selectedAttr);
    }
  }, [action, actionOptions, attributes]);
};

// * Resets attributeId and operatorId upon Action Form reset.
export const useFormResetAttributeIdOperatorId = (plusOperator, assignmentOperator, selectedAttribute, reset) => {
  return useEffect(() => {
    if (!!plusOperator && !!assignmentOperator && selectedAttribute) {
      const operatorId =
        selectedAttribute?.type === ATTRIBUTES_TYPES.WHOLE_NUMBER ? plusOperator.id : assignmentOperator.id;
      reset({ attributeId: selectedAttribute.id, operatorId });
    }
  }, [selectedAttribute?.type, assignmentOperator, plusOperator, selectedAttribute, reset]);
};
