import React, { useCallback, useEffect, memo } from "react";
import { useMutation } from "@apollo/client";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useForm, useFieldArray } from "react-hook-form";
import { Row, Col } from "reactstrap";
import { useSelector } from "react-redux";

import SlackButton from "../../components/SlackButton/SlackButton";
import { ResponsiveVerticalContainer, StyledCardShadow } from "theme/StyledComponents";
import { UPDATE_INTEGRATION_ORDER } from "../../api/integrations";
import { selectProjectData } from "features/userData/userDataSlice";
import { reduceIds } from "utils/list.utils";
import MSTeamsButton from "components/MSTeamsButton";
import { useIntegrations } from "../../hooks/application.hooks";
import IntegrationForm from "../../components/Forms/Integrations/IntegrationForm";

function Integrations() {
  const [updateIntegrationOrder] = useMutation(UPDATE_INTEGRATION_ORDER);
  const projectData = useSelector(selectProjectData);
  const { integrations, refetch } = useIntegrations();

  const { control, getValues, setValue } = useForm({
    defaultValues: {
      integrations: []
    }
  });

  const { fields: dragFields, move } = useFieldArray({
    control,
    name: "integrations"
  });

  const saveIntegrationOrder = async () => {
    const list = reduceIds(getValues().integrations);
    await updateIntegrationOrder({
      variables: { data: { list, projectId: projectData?.id } },
      skip: !projectData?.id,
      fetchPolicy: "no-cache"
    });
  };

  const handleOnDragEnd = useCallback(async ({ destination, source }) => {
    move(source.index, destination.index);
    saveIntegrationOrder();
  }, []);

  useEffect(() => {
    if (integrations?.length) {
      setValue("integrations", integrations);
    } else {
      setValue("integrations", []);
    }
  }, [integrations]);

  return (
    <ResponsiveVerticalContainer>
      <div
        style={{
          maxHeight: "75px",
          padding: "1rem 2rem",
          marginBottom: "3rem",
          display: "flex",
          alignItems: "center",
          width: "85%",
          borderRadius: "10px",
          boxShadow: "0px 3px 10px 2px rgb(0 0 0 / 8%)",
          margin: "auto"
        }}
        className="mb-3"
      >
        <h5 className="mb-0" style={{ marginLeft: "1rem" }}>
          Add Integration:
        </h5>
        <div className="integrations d-flex justify-content-between pl-3" style={{ marginLeft: "1rem" }}>
          <span className="me-3">
            <SlackButton />
          </span>

          <span className="me-3">
            <MSTeamsButton />
          </span>
        </div>
      </div>
      <StyledCardShadow>
        <Row className="p-0">
          <Col className="px-4" id="rulesContainer">
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="integrationList">
                {(provided) => (
                  <span ref={provided.innerRef} {...provided.droppableProps}>
                    {provided.placeholder}
                    {dragFields.map((integration, index) => {
                      const integrationId = getValues().integrations[index].id;
                      return (
                        <Draggable draggableId={integration.id} key={integration.id} index={index}>
                          {(prv) => (
                            <span
                              {...prv.draggableProps}
                              {...prv.dragHandleProps}
                              ref={prv.innerRef}
                              key={integration.id}
                            >
                              <IntegrationForm
                                index={index}
                                integration={integration}
                                integrationId={integrationId}
                                refetchIntegrations={refetch}
                              />
                            </span>
                          )}
                        </Draggable>
                      );
                    })}
                  </span>
                )}
              </Droppable>
            </DragDropContext>
          </Col>
        </Row>
      </StyledCardShadow>
    </ResponsiveVerticalContainer>
  );
}

export default memo(Integrations);
