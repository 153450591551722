import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { omit } from "lodash";

// * COMPONENTS
import { ActionFormButton, FormErrorText } from "../../theme/StyledComponents";
import ColumnSelectionValidation from "./ColumnSelectionValidation";
import CustomSelect from "../CustomSelect";
import styled from "styled-components";
import { UncontrolledInput } from "../CustomInput/CustomInput";

// * Selectors, Hooks, Utils, Mutations
import { callValidateSelectedColumn } from "../../utils/csv.utils";
import messages from "../../api/messages";
import { selectSpecificOperators } from "../../app/operators.slice";
import { selectWholeNumberAttributes } from "../../features/Attributes/AttributesSlice";
import { VALIDATE_ACTION_COLUMN } from "../../api/csv-upload";

// * CSVUploadCustomActionForm: handles the formation, validation, and display of a Custom Action Map (WHOLE_NUMBER MANUAL_ENTRY action)
const CSVUploadCustomActionForm = ({
  headerRow,
  emitCustomAction,
  projectId,
  logId,
  file,
  customActionFromConfig,
  emitCustomActionValid
}) => {
  const [validateSelectedColumn] = useMutation(VALIDATE_ACTION_COLUMN);
  const [actionColumnMessage, setActionColumnMessage] = useState({
    success: false,
    message: messages.COLUMN_SELECTION_REQUIRED
  });
  const [emitted, setEmitted] = useState(false);

  // * CUSTOM ACTION OPERATORS
  const modifierOperators = useSelector(selectSpecificOperators(["NONE", "*", "/"]));
  const [plusOperator, minusOperator] = useSelector(selectSpecificOperators(["+", "-"]));
  const wholeNumberOperators = [plusOperator, minusOperator];
  const wholeNumberAttributes = useSelector(selectWholeNumberAttributes);

  // * INITIALIZE FORM AND WATCH VALUES
  const {
    reset,
    register,
    watch,
    getValues,
    setValue,
    formState: { dirtyFields }
  } = useForm();

  const actionMappedColumn = watch("actionMappedColumn");
  const attributeId = watch("attributeId", wholeNumberAttributes[0].id);
  const customActionName = watch("customActionName");
  const modifierId = watch("modifierId");
  const operatorId = watch("operatorId", wholeNumberOperators[0].id);
  const value = watch("value");
  const [validValue, setValidValue] = useState(true);
  const [initialConfigLoad, setInitialConfigLoad] = useState(false);
  const [editing, setEditing] = useState(false);

  const handleClearForm = () => {
    setEditing(true);
    emitCustomAction(null);
    emitCustomActionValid(false);
    setEmitted(false);
    setValue("actionMappedColumn", null);
    setActionColumnMessage({ success: false, message: messages.COLUMN_SELECTION_REQUIRED, displayValue: null });
    reset();
  };

  const isValid =
    !!attributeId &&
    !!actionMappedColumn &&
    !!customActionName &&
    !!operatorId &&
    actionColumnMessage.success &&
    validValue;

  // * validate action column for whole number values
  useEffect(() => {
    emitCustomActionValid(false);
    if (!!file && !!actionMappedColumn && dirtyFields["actionMappedColumn"] && actionMappedColumn !== "select option") {
      callValidateSelectedColumn(
        actionMappedColumn,
        projectId,
        logId,
        validateSelectedColumn,
        true,
        file,
        setActionColumnMessage
      ).then((res) => {
        if (res?.data.validateActionColumn.success) {
          setActionColumnMessage({
            success: true,
            message: "Valid Whole Number Column in File",
            displayValue: res?.data.validateActionColumn.displayValue
          });
        } else {
          setActionColumnMessage({
            success: false,
            message: res?.data.validateActionColumn.message,
            displayValue: res?.data.validateActionColumn.displayValue
          });
        }
      });
    }
  }, [
    actionMappedColumn,
    dirtyFields,
    setActionColumnMessage,
    file,
    projectId,
    logId,
    validateSelectedColumn,
    customActionFromConfig,
    emitCustomActionValid
  ]);

  // * sets values for a customAction from a loaded config
  useEffect(() => {
    if (!!customActionFromConfig?.customActionName && !initialConfigLoad) {
      setInitialConfigLoad(true);
      setValue("customActionName", customActionFromConfig.customActionName, { shouldDirty: true });
      setValue("actionMappedColumn", customActionFromConfig.actionMappedColumn, { shouldDirty: true });
      setValue("value", customActionFromConfig.customActionValue, { shouldDirty: true });
      setValue("operatorId", customActionFromConfig.customActionOperatorId, { shouldDirty: true });
      setValue("attributeId", customActionFromConfig.customActionAttributeId, { shouldDirty: true });
      setValue("modifierId", customActionFromConfig.customActionModifierId, { shouldDirty: true });
    }
  }, [customActionFromConfig, setValue, setInitialConfigLoad, initialConfigLoad]);

  const handleResetEmitted = () => {
    setEditing(true);
    setEmitted(false);
    emitCustomAction(null);
    emitCustomActionValid(false);
  };

  const handleEmitValidForm = () => {
    const form = getValues();
    setEmitted(true);
    const formForEmit = {
      ...omit(form, ["modifierId", "value"]),
      modifierId: !!form.value && form.value !== "0" ? form.modifierId : modifierOperators[0].id, // * protects against modifying by 0
      value: !!form.modifierId && !!form.value && form.value !== "0" ? form.value : null // * protects against modifying by 0
    };
    emitCustomAction(formForEmit);
    emitCustomActionValid(true);
  };

  // * setValidValue error message if not whole number value
  useEffect(() => {
    if (value === "0" && modifierId === modifierOperators[0].id) {
      setValidValue(true);
      return;
    }

    if (modifierId && modifierId !== modifierOperators[0].id && value && Number.isInteger(+value) && value !== "0") {
      setValidValue(true);
    } else if ((modifierId && modifierId !== modifierOperators[0].id && !Number.isInteger(+value)) || value === "0") {
      setValidValue(false);
    } else if (!value || value !== "0") {
      setValidValue(true);
    }
  }, [value, setValidValue, modifierId, setValue, modifierOperators]);

  // * customActionFromConfig hooks to determine load state
  useEffect(() => {
    if (customActionFromConfig && isValid && !editing) {
      handleEmitValidForm();
    }
  }, [isValid, customActionFromConfig, editing]);

  useEffect(() => {
    setInitialConfigLoad(false);
  }, [customActionFromConfig, setInitialConfigLoad]);

  // * NOTE: a valid and emitted customAction "locks down the template" by use of the !emitted ternaries (AF)
  return (
    <>
      <form data-testid="CSV_UPLOAD_CUSTOM_ACTION_FORM">
        <div className="d-flex flex-row justify-content-start w-100">
          {!emitted ? (
            <UncontrolledInput
              testId="CUSTOM_ACTION_NAME"
              labelText="NAME"
              formRegister={register}
              name="customActionName"
              maxWidth="8rem"
              disabled={emitted}
            />
          ) : (
            <StyledLockedValue>{customActionName}</StyledLockedValue>
          )}

          <div className="ms-1 me-1 m-auto">=</div>

          {!emitted ? (
            <CustomSelect
              className="me-1 m-auto"
              label="ATTRIBUTE"
              formRegister={register}
              name="attributeId"
              options={wholeNumberAttributes}
              width="9rem"
              key="name"
              valueKey="id"
              displayKey="name"
              disabled={emitted}
            />
          ) : (
            <StyledLockedValue>{wholeNumberAttributes.find((att) => att.id === attributeId).name}</StyledLockedValue>
          )}

          {!emitted ? (
            <CustomSelect
              label="OPERATOR"
              className="me-1 m-auto"
              formRegister={register}
              name="operatorId"
              displayKey="displayName"
              key="id"
              valueKey="id"
              width="4rem"
              options={wholeNumberOperators}
              disabled={emitted}
            />
          ) : (
            <StyledLockedValue className="me-1 m-auto">
              {wholeNumberOperators.find((op) => op.id === operatorId).displayValue}
            </StyledLockedValue>
          )}
          {!emitted ? (
            <CustomSelect
              className="me-1 m-auto"
              formRegister={register}
              name="actionMappedColumn"
              label="CSV FIELD"
              options={[{ name: "Select a CSV FIELD", column: "select option", id: 1000 }, ...headerRow]}
              displayKey="name"
              maxWidth="75%"
              width="33%"
              valueKey="column"
              disabled={emitted}
            />
          ) : (
            <StyledLockedValue data-testid="CUSTOM_ACTION_LOCKED_MAPPED_NAME">"{actionMappedColumn}"</StyledLockedValue>
          )}

          {!emitted ? (
            <CustomSelect
              className="me-1 m-auto"
              label="MODIFIER"
              formRegister={register}
              name="modifierId"
              displayKey="displayName"
              valueKay="id"
              width="7rem"
              options={modifierOperators}
              disabled={emitted}
            />
          ) : (
            !!value &&
            validValue &&
            modifierOperators.find((mod) => mod.id === modifierId).displayValue.toLowerCase() !== "none" && (
              <StyledLockedValue>
                {modifierOperators.find((mod) => mod.id === modifierId).displayValue}
              </StyledLockedValue>
            )
          )}

          {!emitted ? (
            <div className="d-flex flex-row justify-content-around">
              <UncontrolledInput
                className={!validValue ? "error me-1 m-auto" : "me-1 m-auto"}
                labelText="VALUE"
                testId="CUSTOM_ACTION_VALUE"
                formRegister={register}
                name="value"
                maxWidth="4rem"
                type="number"
                disabled={
                  emitted ||
                  !modifierId ||
                  modifierOperators.find((mod) => mod.id === modifierId).displayValue.toLowerCase() === "none"
                }
              />
              <FormErrorText className="m-auto">{!validValue ? "Must be whole number." : ""}</FormErrorText>
            </div>
          ) : (
            !!value &&
            !!modifierId &&
            modifierOperators.find((mod) => mod.id === modifierId).displayValue.toLowerCase() !== "none" && (
              <StyledLockedValue>{value}</StyledLockedValue>
            )
          )}

          {!emitted && (
            <ActionFormButton
              data-testid="SUBMIT_CSV_UPLOAD_CUSTOM_ACTION"
              disabled={!isValid}
              type="button"
              onClick={handleEmitValidForm}
              className="btn btn-outline-success shadow border-0"
            >
              <i className="fa fa-check" />
            </ActionFormButton>
          )}

          {emitted && (
            <ActionFormButton
              data-testid="CLEAR_CSV_UPLOAD_CUSTOM_ACTION"
              type="button"
              className="btn border-0 text-primary"
              onClick={handleResetEmitted}
              buttonType="reset"
            >
              <i className="fa fa-undo" />
            </ActionFormButton>
          )}

          <ActionFormButton
            data-testid="CLEAR_CSV_UPLOAD_CUSTOM_ACTION"
            type="button"
            className="btn border-1 text-danger btn-lg justify-content-center d-flex"
            onClick={handleClearForm}
            buttonType="delete"
          >
            <i className="fa fa-times" />
          </ActionFormButton>
        </div>
      </form>
      {!emitted && JSON.stringify(dirtyFields) !== "{}" && (
        <span className="d-flex" style={{ paddingLeft: "10%" }}>
          <ColumnSelectionValidation
            success={actionColumnMessage.success}
            message={actionColumnMessage.message}
            displayValue={actionColumnMessage.success ? null : actionColumnMessage.displayValue}
            testId="customActionSelection"
          />
        </span>
      )}
    </>
  );
};

const StyledLockedValue = styled("div").attrs({
  className: "locked form-value",
  ariaLabel: ".locked-form-value example"
})`
  background-color: transparent;
  width: 66%;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  border: green 1px solid;
  border-radius: 5px;
  margin: auto 0.5rem auto auto;
  text-align: center;
  padding: 0.5rem;
`;

export default CSVUploadCustomActionForm;
