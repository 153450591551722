import React from "react";
import { Modal } from "reactstrap";

import UserCreatedActionForm from "components/Forms/UserCreatedActionForm";

const ActionSubmitModal = ({ toggle, submittableActions, refetchDashboard }) => {
  return (
    <Modal isOpen={true} style={{ transform: "translateY(25%)" }} toggle={toggle}>
      <UserCreatedActionForm
        handleClearForm={toggle}
        submittableActions={submittableActions}
        refetchDashboard={refetchDashboard}
      />
    </Modal>
  );
};

export default React.memo(ActionSubmitModal);
