import React from "react";
import { Modal, ModalBody } from "reactstrap";

const BasicModal = (props) => {
  return (
    <Modal isOpen>
      <ModalBody className="text-center p-0">{props.children}</ModalBody>
    </Modal>
  );
};

export default React.memo(BasicModal);
