import React, { useEffect } from "react";
import { filter, isEmpty, map, range } from "lodash";
import { Col, Row } from "reactstrap";
import { useSelector } from "react-redux";

import CustomSelect from "../../../components/CustomSelect";
import { UncontrolledInput } from "components/CustomInput/CustomInput";
import { useInitialOperatorForRuleCondition, useSetConditionValue, useWhenOperators } from "hooks/form.hooks";
import { ATTRIBUTES_TYPES } from "features/Attributes/utils";
import LogicalOperator from "./LogicalOperator";
import Bracket from "./Bracket";
import { useSelectedAttribute } from "../../../hooks/form.hooks";
import { selectConnectionSides, selectRuleActorOptions } from "features/Attributes/AttributesSlice";
import { FeatureFlag } from "hocs/FeatureFlag/FeatureFlag";
import { FEATURE_FLAGS } from "hocs/FeatureFlag/utils";
import { selectOperatorDict } from "app/operators.slice";
import { FormErrorText } from "theme/StyledComponents";
import {
  RULE_CONDITION_TYPES,
  RULE_CONDITION_VALUE_TYPES,
  ruleConditionBoilerPlate,
  useClearRuleConditionErrors,
  WHEN_CONDITION_VALUE_TYPE_OPTIONS
} from "../utils";
import useFeatureFlag from "hocs/FeatureFlag/hooks/useFeatureFlag";

const WhenConditionBlock = ({ attributes, ruleCondition, index, formUtils }) => {
  const enhancedRuleConditionsEnabled = useFeatureFlag(FEATURE_FLAGS.ENHANCED_RULE_CONDITIONS);
  const detectConnectionsEnabled = useFeatureFlag(FEATURE_FLAGS.DETECT_CONNECTION);
  const operatorDict = useSelector(selectOperatorDict);
  const { watch, register: formRegister, insert, remove, update, fields, setValue } = formUtils;
  const actorOptions = useSelector(selectRuleActorOptions);
  const connectionOptions = useSelector(selectConnectionSides);
  const valueType = formUtils.watch(`ruleConditions.${index}.valueType`);
  const actor = formUtils.watch(`ruleConditions.${index}.actor`, "SELF");
  const attributeId = watch(`ruleConditions.${index}.attributeId`, ruleCondition?.attributeId);
  const operatorId = watch(`ruleConditions.${index}.operatorId`, ruleCondition?.operatorId);
  const selectedAttribute = useSelectedAttribute(attributeId);
  const selectedOperator = operatorDict[operatorId];
  const isHistoricalOperator = selectedOperator?.type === "historical";
  const isLogicalOperator = selectedOperator?.type === "logical";
  const shortenedRuleCondition = isHistoricalOperator || isLogicalOperator;
  const rc = watch(`ruleConditions.${index}`);

  useEffect(() => {
    // since we're doing the weird <connectionId>.<ConnectionSideEnum> here, this makes sure the form is set with the
    // correct initial values if the attributeId refers to a connection
    if (rc.attributeConnectionSide && rc?.attribute?.connection?.id) {
      setValue(
        `ruleConditions.${index}.attributeId`,
        `${rc?.attribute?.connection?.id}.${rc?.attributeConnectionSide}`
      );
    }
  }, [rc.attributeConnectionSide, rc?.attribute?.connection?.id]);

  // UNREGISTER ACTOR/VALUE/VARIABLE/FIELDS TO NULL CLEAR ERRORS WHEN valueType/selectedAttribute?.type Changes
  useClearRuleConditionErrors({
    valueType,
    index,
    unregister: formUtils.unregister,
    selectedAttributeType: selectedAttribute?.type
  });

  useEffect(() => {
    if (shortenedRuleCondition) {
      setValue(`ruleConditions.${index}.value`, null);
      setValue(`ruleConditions.${index}.valueType`, RULE_CONDITION_VALUE_TYPES.VALUE);
    }
  }, [shortenedRuleCondition, setValue, index]);

  const operators = useWhenOperators(
    selectedAttribute?.type ?? ATTRIBUTES_TYPES.WHOLE_NUMBER,
    actor,
    enhancedRuleConditionsEnabled
  );

  // TODO: REFACTOR
  const handleAndOrClick = (type, i) => () => {
    const condition = formUtils.getValues().ruleConditions[i];
    const previousValue = condition.logicalOperator;
    const newValue = previousValue === type ? null : type;
    update(i, {
      ...condition,
      logicalOperator: newValue
    });

    switch (true) {
      case newValue === null:
        const toRemove = range(i + 1, fields.length - 1);
        remove(toRemove);
        break;
      case ["AND", "OR"].includes(newValue) && !previousValue:
        const template = ruleConditionBoilerPlate(attributes);
        insert(i + 1, template);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setValue(`ruleConditions.${index}.actor`, ruleCondition?.actor ?? "SELF");
  }, []); // eslint-disable-line

  useSetConditionValue(selectedAttribute, setValue, `ruleConditions.${index}.value`, ruleCondition?.value); // sets to '0' if switching back to WHOLE_NUMBER re: avoid showing uuids

  useInitialOperatorForRuleCondition(
    ruleCondition?.operatorId,
    selectedAttribute?.type,
    RULE_CONDITION_TYPES.WHEN,
    setValue,
    `ruleConditions.${index}.operatorId`
  );

  const operatorIds = map(operators, "id");

  // WHEN SELECTED ATTRIBUTE CHANGES, SOMETIMES AN OPERATORS IS NOT RELEVANT
  // IN THAT CASE, SET IT TO THE FIRST ON THE LIST
  useEffect(() => {
    setTimeout(() => {
      if (!operatorIds.includes(operatorId) && !isEmpty(operatorIds)) {
        setValue(`ruleConditions.${index}.operatorId`, operatorIds[0]);
      }
    }, 1);
  }, [selectedAttribute?.id, operatorId, operatorIds]); // eslint-disable-line

  if (selectedAttribute === undefined || !operators.length > 0) {
    return null;
  }

  return (
    <>
      <div className="d-inline-flex justify-content-center gap-2 w-100 flex-wrap py-0">
        <span
          className="me-1"
          style={{
            fontWeight: 700,
            fontSize: "20px",
            visibility: index === 0 ? "inherit" : "hidden"
          }}
        >
          {ruleCondition.type}
        </span>
        <span className="pt-2">
          <Bracket type="LEFT" formRegister={formRegister} index={index} active={ruleCondition.isLeftBracketActive} />
        </span>

        <FeatureFlag featureName={FEATURE_FLAGS.CONNECTIONS}>
          <span className="mb-2">
            {actorOptions.length > 0 && (
              <CustomSelect
                name={`ruleConditions.${index}.actor`}
                formRegister={formRegister}
                options={actorOptions}
                defaultValue="SELF"
                label="ACTOR"
                displayKey="name"
                valueKey="id"
                style={{ minWidth: "10rem" }}
                validation={{ required: true }}
              />
            )}
          </span>
        </FeatureFlag>

        <span className="mb-2">
          <CustomSelect
            defaultValue={selectedAttribute?.id}
            name={`ruleConditions.${index}.attributeId`}
            formRegister={formRegister}
            options={attributes.concat(detectConnectionsEnabled ? connectionOptions : [])}
            label="ATTRIBUTE"
            displayKey="name"
            style={{ minWidth: "10rem" }}
            validation={{ required: true }}
          />
        </span>
        <span className="mb-2">
          <CustomSelect
            defaultValue={ruleCondition?.operatorId}
            name={`ruleConditions.${index}.operatorId`}
            formRegister={formRegister}
            options={operators}
            label="OPERATOR"
            displayKey="displayValue"
            maxWidth="100%"
            style={{
              width: "100%",
              margin: "auto"
            }}
            validation={{ required: true }}
          />
        </span>

        {!shortenedRuleCondition && (
          <>
            <span className="mb-2">
              <CustomSelect
                name={`ruleConditions.${index}.valueType`}
                formRegister={formRegister}
                options={
                  enhancedRuleConditionsEnabled
                    ? WHEN_CONDITION_VALUE_TYPE_OPTIONS
                    : WHEN_CONDITION_VALUE_TYPE_OPTIONS.slice(0, 1)
                }
                label="VALUE TYPE"
                displayKey="name"
                maxWidth="100%"
                style={{
                  width: "100%",
                  margin: "auto"
                }}
                className={formUtils?.formState?.errors?.ruleConditions?.[index]?.value ? "error" : ""}
                validation={{ required: "required" }}
              />
              {formUtils?.formState?.errors?.ruleConditions?.[index]?.valueType && (
                <FormErrorText>{formUtils.formState.errors.ruleConditions[index].valueType.message}</FormErrorText>
              )}
            </span>
          </>
        )}

        {valueType === RULE_CONDITION_VALUE_TYPES.ACTOR_ATTRIBUTE && (
          <>
            <FeatureFlag featureName={FEATURE_FLAGS.CONNECTIONS}>
              <span className="mb-2">
                {actorOptions.length > 0 && (
                  <CustomSelect
                    name={`ruleConditions.${index}.secondaryActor`}
                    formRegister={formRegister}
                    options={actorOptions}
                    defaultValue="SELF"
                    label="ACTOR"
                    displayKey="name"
                    valueKey="id"
                    style={{ minWidth: "10rem" }}
                    validation={{ required: true }}
                  />
                )}
              </span>
              <span className="mb-2">
                <CustomSelect
                  defaultValue={selectedAttribute?.id}
                  name={`ruleConditions.${index}.secondaryAttribute.id`}
                  formRegister={formRegister}
                  options={filter(attributes, { type: selectedAttribute?.type })}
                  label="ATTRIBUTE"
                  displayKey="name"
                  style={{ minWidth: "10rem" }}
                  validation={{ required: true }}
                />
              </span>
            </FeatureFlag>
          </>
        )}

        {!shortenedRuleCondition && valueType === RULE_CONDITION_VALUE_TYPES.VALUE && (
          <>
            {selectedAttribute?.type === ATTRIBUTES_TYPES.WHOLE_NUMBER && (
              <span className="mb-2">
                <UncontrolledInput
                  name={`ruleConditions.${index}.value`}
                  formRegister={formRegister}
                  labelText={RULE_CONDITION_VALUE_TYPES.VALUE}
                  defaultValue={0}
                  style={{ maxWidth: "10rem" }}
                  className={formUtils?.formState?.errors?.ruleConditions?.[index]?.value ? "error" : ""}
                  type="number"
                  validation={{
                    validate: (value) => Number.isInteger(Number.parseFloat(value)) || "Must be an integer"
                  }}
                />
                {formUtils?.formState?.errors?.ruleConditions?.[index]?.value && (
                  <FormErrorText>{formUtils.formState.errors.ruleConditions[index].value.message}</FormErrorText>
                )}
              </span>
            )}
            {selectedAttribute?.type === ATTRIBUTES_TYPES.VALUE_LIST && (
              <span>
                <CustomSelect
                  name={`ruleConditions.${index}.value`}
                  formRegister={formRegister}
                  options={selectedAttribute.attributeValues ?? []}
                  label={RULE_CONDITION_VALUE_TYPES.VALUE}
                  displayKey="name"
                  style={{ minWidth: "10rem", maxWidth: "10rem" }}
                  validation={{ required: true }}
                />
              </span>
            )}
          </>
        )}

        <span className="pt-2">
          <Bracket type="RIGHT" formRegister={formRegister} index={index} active={ruleCondition.isRightBracketActive} />
        </span>
      </div>
      <Row className="mt-2 mb-3">
        <Col md={12} className="d-flex justify-content-around" style={{ width: "500px", margin: "auto" }}>
          <LogicalOperator
            type="AND"
            handleClick={handleAndOrClick("AND", index)}
            active={ruleCondition.logicalOperator === "AND"}
            testId={`ruleConditions.${index}.AND`}
          />
          <LogicalOperator
            type="OR"
            handleClick={handleAndOrClick("OR", index)}
            active={ruleCondition.logicalOperator === "OR"}
            testId={`ruleConditions.${index}.OR`}
          />
        </Col>
      </Row>
    </>
  );
};

export default WhenConditionBlock;
