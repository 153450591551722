import { gql } from "@apollo/client";

export const SAVE_RULE = gql`
  mutation saveRule($data: UpdateOrCreateRuleInput!) {
    saveRule(data: $data) {
      message
      success
    }
  }
`;

export const GET_RULES = gql`
  query GET_RULES($projectId: String!) {
    getRules(projectId: $projectId) {
      id
      name
      projectId
      active
      index
      createdAt
      sendNotifications
      ruleConditions {
        id
        attributeId
        attributeConnectionSide
        logicalOperator
        value
        isLeftBracketActive
        isRightBracketActive
        type
        operatorId
        index
        actor
        customActionId
        secondaryAttribute {
          id
          name
          value
          displayValue
        }
        attribute {
          id
          type
          value
          name
          iconName
          iconUrl
          active
          projectId
          connection {
            id
          }
          attributeValues {
            id
            name
            iconName
            iconUrl
            active
          }
        }
        operator {
          active
          id
          type
          displayValue
          value
        }
        modifierId
        modifyAmount
        secondaryActor
        modifier {
          id
          type
          displayValue
          value
        }
        valueType
        variable
      }
    }
  }
`;

export const DELETE_RULE = gql`
  mutation deleteRule($id: String!) {
    deleteRule(id: $id) {
      success
      message
    }
  }
`;

export const UPDATE_RULE_ORDER = gql`
  mutation updateRuleOrder($data: UpdateListOrderArgs!) {
    updateRuleOrder(data: $data) {
      success
      message
    }
  }
`;
