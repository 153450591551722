import { useQuery } from "@apollo/client";
import { selectProjectData } from "features/userData/userDataSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { GET_PROJECT_USERS } from "api/users";
import { saveUsers, selectUsers } from "app/project.slice";

export const useProjectUsers = () => {
  const dispatch = useDispatch();
  const projectData = useSelector(selectProjectData);
  const users = useSelector(selectUsers);

  const { data, loading, refetch } = useQuery(GET_PROJECT_USERS, {
    variables: { projectId: projectData?.id },
    skip: !projectData?.id
  });

  const stateAndApiEqual = isEqual(JSON.stringify(users), JSON.stringify(data?.projectUsers));

  useEffect(() => {
    if (!stateAndApiEqual && Array.isArray(data?.projectUsers)) {
      console.count("setting users");
      dispatch(saveUsers(data?.projectUsers));
    }
  }, [stateAndApiEqual, dispatch, data?.projectUsers]);

  return { loading, refetch };
};
