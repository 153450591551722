import React, { useEffect, useState } from "react";
import { forEach, isEmpty, isNil, map } from "lodash";
import { useSelector } from "react-redux";

import CustomSelect from "../../../components/CustomSelect";
import { UncontrolledInput } from "components/CustomInput/CustomInput";
import { useInitialOperatorForRuleCondition, useSetConditionValue, useThenOperators } from "hooks/form.hooks";
import { ATTRIBUTES_TYPES } from "features/Attributes/utils";
import { useSelectedAttribute } from "../../../hooks/form.hooks";
import { selectAttributeDict, selectRuleActorOptions } from "features/Attributes/AttributesSlice";
import { FEATURE_FLAGS } from "hocs/FeatureFlag/utils";
import useFeatureFlag from "hocs/FeatureFlag/hooks/useFeatureFlag";
import { selectOperatorDict, selectSpecificOperators } from "app/operators.slice";
import { FormErrorText } from "theme/StyledComponents";
import {
  RULE_CONDITION_TYPES,
  RULE_CONDITION_VALUE_TYPES,
  THEN_CONDITION_VALUE_TYPE_OPTIONS,
  useClearRuleConditionErrors,
  validateThenConditionValue
} from "../utils";
import { selectCustomActionsDict, selectCustomActionsWithResult } from "../../../app/actions.slice";
import { ACTION_TYPES } from "../../Actions/utils";
import { FeatureFlag } from "../../../hocs/FeatureFlag/FeatureFlag";

const ThenConditionBlock = ({ attributes, ruleCondition, index, formUtils, modifierOperators }) => {
  const enhancedRuleConditionsEnabled = useFeatureFlag(FEATURE_FLAGS.ENHANCED_RULE_CONDITIONS);
  const [thenValueTypeOptions, setThenValueTypeOptions] = useState(THEN_CONDITION_VALUE_TYPE_OPTIONS.slice(0, 1));
  const operatorDict = useSelector(selectOperatorDict);
  const attributeDict = useSelector(selectAttributeDict);
  const customActions = useSelector(selectCustomActionsWithResult);
  const customActionsDict = useSelector(selectCustomActionsDict);
  const { watch, register: formRegister, setValue } = formUtils;
  const actorOptions = useSelector(selectRuleActorOptions);
  const valueType = formUtils.watch(`ruleConditions.${index}.valueType`);
  const attributeId = watch(`ruleConditions.${index}.attributeId`, ruleCondition?.attributeId);
  const modifierId = watch(`ruleConditions.${index}.modifierId`, ruleCondition?.modifierId);
  const modifier = operatorDict[modifierId];
  const allRuleConditions = formUtils.watch("ruleConditions");
  const allowableVariables = [];
  const stringifiedVariables = JSON.stringify(allowableVariables);
  const operatorId = watch(`ruleConditions.${index}.operatorId`, ruleCondition?.operatorId ?? modifierOperators[0].id);
  const customActionId = watch(
    `ruleConditions.${index}.customActionId`,
    ruleCondition?.customActionId ?? ACTION_TYPES.MANUAL_ENTRY
  );
  const selectedAttribute = useSelectedAttribute(attributeId);
  const customAction = customActionsDict[customActionId];

  // ADD VARIABLE OPTION TO allowableVariables as they are created
  forEach(allRuleConditions, (rc) => {
    const op = operatorDict[rc?.operatorId];
    const attr = attributeDict[rc?.attributeId];
    if (op?.type === "historical") {
      allowableVariables.push({
        id: `${rc?.attributeId} ${op?.value}`,
        name: `Self ${attr?.name} ${op?.displayValue}`
      });
    }
  });

  const canUseModifier = allowableVariables?.length && enhancedRuleConditionsEnabled;

  useEffect(() => {
    setValue(`ruleConditions.${index}.customActionId`, ruleCondition?.customActionId ?? ACTION_TYPES.MANUAL_ENTRY);
  }, []);

  useEffect(() => {
    if (customAction) {
      const accessor = `ruleConditions.${index}`;
      const rc = formUtils.getValues(accessor);
      setValue(accessor, {
        ...rc,
        valueType: RULE_CONDITION_VALUE_TYPES.VALUE,
        attributeId: customAction?.attribute?.id,
        operatorId: customAction?.operator?.id,
        value: customAction?.value,
        modifierId: null,
        modifyAmount: null,
        variable: null,
        operator: null
      });
    }
  }, [customAction]);

  useEffect(() => {
    if (canUseModifier) {
      setThenValueTypeOptions(THEN_CONDITION_VALUE_TYPE_OPTIONS);
    } else {
      setThenValueTypeOptions(THEN_CONDITION_VALUE_TYPE_OPTIONS.slice(0, 1));
      const lastIndex = allRuleConditions?.length - 1;
      setValue(`ruleConditions.${lastIndex}.valueType`, RULE_CONDITION_VALUE_TYPES.VALUE);
    }
  }, [canUseModifier, allRuleConditions?.length, setValue]);

  useClearRuleConditionErrors({
    valueType,
    index,
    unregister: formUtils.unregister,
    selectedAttributeType: selectedAttribute?.type
  });

  useEffect(() => {
    if (selectedAttribute?.type === ATTRIBUTES_TYPES.VALUE_LIST) {
      setThenValueTypeOptions(THEN_CONDITION_VALUE_TYPE_OPTIONS.slice(0, 1));
      setValue(`ruleConditions.${index}.valueType`, RULE_CONDITION_VALUE_TYPES.VALUE);
    }
    if (selectedAttribute?.type === ATTRIBUTES_TYPES.WHOLE_NUMBER && canUseModifier) {
      setThenValueTypeOptions(THEN_CONDITION_VALUE_TYPE_OPTIONS);
    }
  }, [selectedAttribute?.type, setValue, index, canUseModifier]);

  const operators = useThenOperators(
    selectedAttribute?.type ?? ATTRIBUTES_TYPES.WHOLE_NUMBER,
    canUseModifier,
    allRuleConditions?.[index]?.actor
  );

  useEffect(() => {
    setValue(`ruleConditions.${index}.actor`, ruleCondition?.actor ?? "SELF");
  }, []); // eslint-disable-line

  useSetConditionValue(selectedAttribute, setValue, `ruleConditions.${index}.value`, ruleCondition?.value);

  useInitialOperatorForRuleCondition(
    ruleCondition?.operatorId,
    selectedAttribute?.type,
    RULE_CONDITION_TYPES.THEN,
    setValue,
    `ruleConditions.${index}.operatorId`,
    customAction
  );

  const operatorIds = map(operators, "id");
  // WHEN SELECTED ATTRIBUTE CHANGES, SOMETIMES AN OPERATORS IS NOT RELEVANT
  // IN THAT CASE, SET IT TO THE FIRST ON THE LIST
  useEffect(() => {
    setTimeout(() => {
      if (!operatorIds.includes(operatorId) && !isEmpty(operatorIds)) {
        setValue(`ruleConditions.${index}.operatorId`, operatorIds[0]);
      }
    }, 1);
  }, [selectedAttribute?.id, operatorId, operatorIds]); // eslint-disable-line
  // WHEN SELECTED ATTRIBUTE CHANGES, SOMETIMES AN OPERATORS IS NOT RELEVANT
  // IN THAT CASE, SET IT TO THE FIRST ON THE LIST

  useEffect(() => {
    if (modifier?.value === "NONE") {
      setValue(`ruleConditions.${index}.modifyAmount`, 0);
    }
  }, [modifierId, setValue, index]); // eslint-disable-line

  // this prevents getting an api error if a variable is removed and not set correctly by the form
  useEffect(() => {
    formUtils.unregister(`ruleConditions.${index}.variable`);
  }, [stringifiedVariables]); // eslint-disable-line

  if (selectedAttribute === undefined || !operators.length > 0) {
    return null;
  }

  return (
    <>
      <div className="d-inline-flex justify-content-center gap-2 w-100 flex-wrap py-0">
        <span
          className="me-1"
          style={{
            fontWeight: 700,
            fontSize: "20px"
          }}
        >
          {ruleCondition.type}
        </span>
        <FeatureFlag featureName={FEATURE_FLAGS.CONNECTIONS}>
          <span className="mb-2">
            {actorOptions.length > 0 && (
              <CustomSelect
                name={`ruleConditions.${index}.actor`}
                formRegister={formRegister}
                options={actorOptions}
                defaultValue="SELF"
                label="ACTOR"
                displayKey="name"
                valueKey="id"
                style={{ minWidth: "10rem" }}
                validation={{ required: true }}
              />
            )}
          </span>
        </FeatureFlag>
        <FeatureFlag featureName={FEATURE_FLAGS.RULES_FIRE_ACTIONS}>
          <span className="mb-2">
            <CustomSelect
              name={`ruleConditions.${index}.customActionId`}
              formRegister={formRegister}
              options={[
                {
                  id: ACTION_TYPES.MANUAL_ENTRY,
                  name: "Manual Entry"
                }
              ].concat(customActions)}
              label="ACTION"
              displayKey="name"
              valueKey="id"
              style={{ minWidth: "10rem" }}
              validation={{ required: true }}
            />
          </span>
        </FeatureFlag>
        {customActionId !== ACTION_TYPES.MANUAL_ENTRY ? (
          <span className="d-flex ms-4 align-self-center">
            {customAction?.result} {customAction?.applyStrategy && `(${customAction.applyStrategy} based action)`}
          </span>
        ) : (
          <>
            <span className="mb-2">
              <CustomSelect
                defaultValue={selectedAttribute?.id}
                name={`ruleConditions.${index}.attributeId`}
                formRegister={formRegister}
                options={attributes}
                label="ATTRIBUTE"
                displayKey="name"
                style={{ minWidth: "10rem" }}
                validation={{ required: true }}
              />
            </span>

            <span className="mb-2">
              <CustomSelect
                defaultValue={ruleCondition?.operatorId}
                name={`ruleConditions.${index}.operatorId`}
                formRegister={formRegister}
                options={operators}
                label="OPERATOR"
                displayKey="displayValue"
                maxWidth="100%"
                style={{
                  width: "5rem",
                  margin: "auto"
                }}
                validation={{ required: true }}
              />
            </span>

            <span className="mb-2">
              <CustomSelect
                name={`ruleConditions.${index}.valueType`}
                formRegister={formRegister}
                options={thenValueTypeOptions}
                label="VALUE TYPE"
                displayKey="name"
                maxWidth="100%"
                style={{
                  width: "7rem",
                  margin: "auto"
                }}
                validation={{ required: true }}
              />
            </span>
            {formUtils?.formState?.errors?.ruleConditions?.[index]?.modifyAmount && (
              <FormErrorText>{formUtils.formState.errors.ruleConditions[index].modifyAmount?.message}</FormErrorText>
            )}

            {valueType === RULE_CONDITION_VALUE_TYPES.VARIABLE && (
              <>
                <span className="mb-2" style={{ minWidth: "5rem" }}>
                  <CustomSelect
                    name={`ruleConditions.${index}.variable`}
                    formRegister={formRegister}
                    options={allowableVariables}
                    label={RULE_CONDITION_VALUE_TYPES.VARIABLE}
                    displayKey="name"
                    maxWidth="100%"
                    style={{
                      width: "12rem",
                      margin: "auto"
                    }}
                    validation={{ required: "Required" }}
                  />
                  {formUtils?.formState?.errors?.ruleConditions?.[index]?.variable && (
                    <FormErrorText>{formUtils.formState.errors.ruleConditions[index].variable?.message}</FormErrorText>
                  )}
                </span>
                <span className="mb-2" style={{ minWidth: "3rem" }}>
                  <CustomSelect
                    name={`ruleConditions.${index}.modifierId`}
                    formRegister={formRegister}
                    options={modifierOperators}
                    label="MODIFIER"
                    displayKey="name"
                    maxWidth="100%"
                    validation={{ required: "Required" }}
                  />
                  {formUtils?.formState?.errors?.ruleConditions?.[index]?.modifierId && (
                    <FormErrorText>{formUtils.formState.errors.ruleConditions[index].modifierId.message}</FormErrorText>
                  )}
                </span>
                {modifier?.value !== "NONE" && (
                  <span className="mb-2" style={{ minWidth: "3rem" }}>
                    <UncontrolledInput
                      name={`ruleConditions.${index}.modifyAmount`}
                      formRegister={formRegister}
                      labelText="AMOUNT"
                      defaultValue={0}
                      style={{ maxWidth: "4rem" }}
                      className={formUtils?.formState?.errors?.ruleConditions?.[index]?.modifyAmount ? "error" : ""}
                      type="number"
                      validation={{
                        validate: (value) => {
                          if (valueType === RULE_CONDITION_VALUE_TYPES.VARIABLE) {
                            return (
                              (!isNil(value) && Number.isInteger(Number(value)) && value >= 0) ||
                              "Must be an integer >= 0"
                            );
                          } else {
                            return null;
                          }
                        }
                      }}
                    />
                    {formUtils?.formState?.errors?.ruleConditions?.[index]?.modifyAmount && (
                      <FormErrorText>
                        {formUtils.formState.errors.ruleConditions[index].modifyAmount?.message}
                      </FormErrorText>
                    )}
                  </span>
                )}
              </>
            )}

            {valueType === RULE_CONDITION_VALUE_TYPES.VALUE && (
              <>
                {selectedAttribute?.type === ATTRIBUTES_TYPES.WHOLE_NUMBER && (
                  <span className="mb-2">
                    <UncontrolledInput
                      name={`ruleConditions.${index}.value`}
                      formRegister={formRegister}
                      labelText={RULE_CONDITION_VALUE_TYPES.VALUE}
                      defaultValue={0}
                      style={{ maxWidth: "10rem" }}
                      validation={{
                        validate: (value) => validateThenConditionValue(value, selectedAttribute)
                      }}
                      className={formUtils?.formState?.errors?.ruleConditions?.[index]?.value ? "error" : ""}
                      type="number"
                    />
                    {formUtils?.formState?.errors?.ruleConditions?.[index]?.value && (
                      <FormErrorText>{formUtils.formState.errors.ruleConditions[index].value.message}</FormErrorText>
                    )}
                  </span>
                )}
                {selectedAttribute?.type === ATTRIBUTES_TYPES.VALUE_LIST && (
                  <span>
                    <CustomSelect
                      name={`ruleConditions.${index}.value`}
                      formRegister={formRegister}
                      options={selectedAttribute.attributeValues ?? []}
                      label={RULE_CONDITION_VALUE_TYPES.VALUE}
                      displayKey="name"
                      style={{ minWidth: "10rem", maxWidth: "10rem" }}
                      validation={{
                        validate: (value) => !isNil(value) || "Required"
                      }}
                    />
                    {formUtils?.formState?.errors?.ruleConditions?.[index]?.value && (
                      <FormErrorText>{formUtils.formState.errors.ruleConditions[index].value.message}</FormErrorText>
                    )}
                  </span>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ThenConditionBlock;
