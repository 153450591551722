import React from "react";
import { useMutation } from "@apollo/client";

import { DELETE_TEAM } from "api/teams";
import DeleteRow from "./DeleteRow/DeleteRow";
import BasicModal from "./BasicModal";
import TeamForm from "./Forms/TeamForm";
import { useSubmit } from "hooks/form.hooks";
import messages from "api/messages";

const NewTeamLabel = ({ handleClick }) => {
  return (
    <div data-testid="NEW_TEAM" onClick={handleClick} className="d-flex w-100 justify-content-between">
      <span>New Team...</span>
    </div>
  );
};

const ManageTeam = ({ team, newTeam = false, handleSuccess, isAdmin = false }) => {
  const [showModal, setShowModal] = React.useState(false);
  const toggle = React.useCallback(
    (e) => {
      setShowModal(!showModal);
      if (!!e) {
        e.stopPropagation();
      }
    },
    [setShowModal, showModal]
  );
  const [deleteTeam] = useMutation(DELETE_TEAM, {
    variables: {
      id: team?.id
    },
    skip: !team?.id
  });

  const handleDelete = useSubmit({
    mutation: deleteTeam,
    dataPath: DELETE_TEAM.definitions[0].name.value,
    useVariables: true,
    onSuccess: handleSuccess
  });

  return (
    <>
      {showModal && (
        <BasicModal testId="Team-modal">
          <TeamForm team={team} edit={!newTeam} handleClearForm={toggle} handleSuccess={handleSuccess} />
        </BasicModal>
      )}
      {newTeam && isAdmin ? (
        <NewTeamLabel handleClick={toggle} />
      ) : (
        <div className="d-flex w-100 justify-content-between">
          <span
            style={{
              whiteSpace: "pre",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "12rem"
            }}
          >
            {team?.name}
          </span>
          {isAdmin && (
            <span
              style={{ width: "2.5rem", cursor: "pointer", marginLeft: "1rem" }}
              className="edit-delete text-end d-flex align-content-center justify-content-between"
            >
              <span onClick={toggle}>
                <i
                  className="fa fa-edit"
                  style={{
                    color: "green",
                    fontSize: "16px"
                  }}
                />
              </span>
              <span data-testid={`DELETE_TEAM-${team?.name}`}>
                <DeleteRow confirmText={messages.CONFIRM_DELETE_TEAM} handleDelete={handleDelete} shouldUseModal icon />
              </span>
            </span>
          )}
        </div>
      )}
    </>
  );
};

export default React.memo(ManageTeam);
