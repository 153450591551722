import React, { useEffect, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import { find } from "lodash";
import PropTypes from "prop-types";
import styled from "styled-components";
import messages from "../../api/messages";
import { CREATE_CUSTOM_ACTION } from "api/actions";

// * Components
import ActionExpirationForm from "./ActionExpirationForm";
import { ActionFormButton } from "theme/StyledComponents";
import CustomCheckbox from "components/CustomCheckbox";
import CustomSelect from "components/CustomSelect";
import { FeatureFlag } from "../../hocs/FeatureFlag/FeatureFlag";
import { UncontrolledInput } from "components/CustomInput/CustomInput";

// * Slices
import { selectNonConnectionAttributes } from "features/Attributes/AttributesSlice";
import { selectProjectData } from "../../features/userData/userDataSlice";
import { selectSpecificOperators } from "app/operators.slice";

// * Utils/Hooks
import { ACTION_TYPES, EVENT_OPTIONS, useActionOptions } from "../../features/Actions/utils";
import { ATTRIBUTES_TYPES } from "features/Attributes/utils";
import { FEATURE_FLAGS } from "../../hocs/FeatureFlag/utils";
import { inputValidation } from "../../utils/form.utils";
import {
  useHasDirtyForm,
  useIntendedInitialOperator,
  useSelectedAttribute,
  useSetConditionValue,
  useShouldResetForm,
  useSubmit
} from "../../hooks/form.hooks";
import { usePreviousValue } from "../../hooks/api.hooks";

const Form = styled.form`
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
`;

const ButtonsContainer = styled.div`
  margin-left: auto;
  width: 9rem;
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 0;
  top: 0;
`;

const FormRow = styled.div.attrs({ className: "d-inline-flex justify-content-start align-items-center w-100" })`
  flex-wrap: wrap;
  max-width: 85%;
  & > * {
    margin-bottom: 0.5rem;
  }
`;

function CustomActionForm({
  handleClearForm = () => ({}),
  handleDelete = () => ({}),
  handleSaveSuccess,
  action = {},
  setDirtyForms,
  actionId
}) {
  const { slackChannels, msTeamsChannels } = useSelector((state) => state.integrations);

  const attributes = useSelector(selectNonConnectionAttributes);
  const operators = useSelector(selectSpecificOperators(["+", "-", "="]));
  const [formType, setFormType] = useState(action?.type || ACTION_TYPES.MANUAL_ENTRY);

  const {
    setValue,
    register,
    reset,
    watch,
    handleSubmit,
    formState: { isDirty, isValid, dirtyFields }
  } = useForm({
    defaultValues: { ...action, approvalRequired: false, applyStrategy: "INSTANCE" },
    mode: "onChange"
  });
  const approvalRequired = watch("approvalRequired");
  const attributeId = watch("attributeId");
  const selectedAttribute = useSelectedAttribute(attributeId);
  const actionOptions = useActionOptions(slackChannels, msTeamsChannels);
  const previousAction = usePreviousValue(action);
  const [createCustomAction] = useMutation(CREATE_CUSTOM_ACTION);
  const projectData = useSelector(selectProjectData);
  const [plusOperator, equalsOperator] = useSelector(selectSpecificOperators(["+", "="]));

  const applyStrategy = watch("applyStrategy");

  const lookup = {
    MESSAGE: "Channel Post",
    REACTION: "Reaction",
    REPLY: "Reply"
  };

  const processSlackActionForm = (formData) => {
    const channelName = find(slackChannels, (c) => c.id === formData.channelId).name;

    const payload = {
      ...formData,
      channelName,
      name: `Slack ${lookup[formData.incomingEventType]} #${channelName}`,
      approvalRequired: false
    };

    return payload;
  };

  const processTeamsActionForm = (formData) => {
    const channelName = find(msTeamsChannels, (c) => c.id === formData.channelId).name;
    const payload = {
      ...formData,
      channelName,
      name: `MS Teams ${lookup[formData.incomingEventType]} #${channelName}`,
      approvalRequired: false
    };

    return payload;
  };

  const processCustomActionForm = (formData) => {
    return {
      ...formData,
      incomingEventType: null,
      channelId: null,
      channelName: null
    };
  };

  const extractActionFormData = (formData) => {
    switch (formType) {
      case ACTION_TYPES.SLACK:
        return processSlackActionForm(formData);
      case ACTION_TYPES.TEAMS:
        return processTeamsActionForm(formData);
      default:
        return processCustomActionForm(formData);
    }
  };

  const handleUpdateSuccess = (action) => {
    reset(action);
  };

  const setExpirationValues = (values) => {
    if (values.expires && applyStrategy !== "STATEFUL") {
      setValue("expirationPeriodAmount", +values.periodAmount);
      setValue("expirationPeriod", values.period);
    } else {
      setValue("expirationPeriodAmount", null);
      setValue("expirationPeriod", null);
    }
  };

  const onSubmit = useSubmit({
    mutation: createCustomAction,
    successMessage: messages.CUSTOM_ACTION_SAVE_SUCCESS,
    errorMessage: messages.CUSTOM_ACTION_SAVE_ERROR,
    clearForm: handleClearForm,
    dataPath: CREATE_CUSTOM_ACTION.definitions[0].name.value,
    onSuccess: !!handleSaveSuccess ? handleSaveSuccess : handleUpdateSuccess,
    extractFormData: (formData) => ({
      ...extractActionFormData(formData),
      id: actionId,
      projectId: projectData?.id
    })
  });

  useShouldResetForm(action, previousAction, reset);
  useIntendedInitialOperator(action?.operatorId, selectedAttribute?.type, setValue);
  useHasDirtyForm(Object.keys(dirtyFields).length > 0, actionId, setDirtyForms);
  const isMessagingIntegrationAction = formType === ACTION_TYPES.TEAMS || formType === ACTION_TYPES.SLACK;

  useEffect(() => {
    reset();
  }, [reset, actionOptions.length]);

  useEffect(() => {
    if (isMessagingIntegrationAction) {
      setValue("applyStrategy", "INSTANCE");
    }
  }, [setValue, isMessagingIntegrationAction]);

  useSetConditionValue(selectedAttribute, setValue, "value", action?.value);

  const displayEventName = useCallback(
    (_action) => {
      switch (formType) {
        case ACTION_TYPES.TEAMS:
          return (
            <CustomSelect
              label="ACTION"
              formRegister={register}
              name="incomingEventType"
              options={EVENT_OPTIONS}
              displayKey="name"
              width="9rem"
              defaultValue={_action?.incomingEventType}
              validation={inputValidation}
            />
          );
        case ACTION_TYPES.SLACK:
          return (
            <CustomSelect
              label="ACTION"
              formRegister={register}
              name="incomingEventType"
              options={EVENT_OPTIONS}
              displayKey="name"
              width="9rem"
              defaultValue={_action?.incomingEventType}
              validation={inputValidation}
            />
          );
        default:
          return (
            <UncontrolledInput
              labelText="NAME"
              formRegister={register}
              name="name"
              validation={inputValidation}
              width="13rem"
              defaultValue={_action?.name}
              testId="actionName"
            />
          );
      }
    },
    [formType, register]
  );

  if (!selectedAttribute) {
    return null;
  }

  const handleFormSelect = ({ target: { value } }) => {
    setFormType(value);
  };

  const actionTypeDropdownWidth = formType === ACTION_TYPES.SLACK ? "6.5rem" : "14rem";

  return (
    <Form className="pe-0" onSubmit={handleSubmit(onSubmit)} data-testid="CUSTOM_ACTION_FORM">
      <FormRow className="gap-2">
        <CustomSelect
          label="SOURCE"
          name="type"
          options={actionOptions}
          width={actionTypeDropdownWidth}
          maxWidth={actionTypeDropdownWidth}
          onChange={handleFormSelect}
          formRegister={register}
          defaultValue={action?.type}
        />

        {displayEventName(action)}

        {formType === ACTION_TYPES.SLACK && (
          <CustomSelect
            label="CHANNEL"
            formRegister={register}
            name="channelId"
            options={slackChannels}
            displayKey="name"
            width="8.5rem"
            defaultValue={action?.channelId}
            validation={inputValidation}
          />
        )}

        {formType === ACTION_TYPES.TEAMS && (
          <CustomSelect
            label="CHANNEL"
            formRegister={register}
            name="channelId"
            options={msTeamsChannels}
            displayKey="name"
            width="8.5rem"
            defaultValue={action?.channelId}
            validation={inputValidation}
          />
        )}

        <strong className="mx-2">=</strong>

        <CustomSelect
          label="ATTRIBUTE"
          formRegister={register}
          name="attributeId"
          options={attributes}
          displayKey="name"
          defaultValue={action?.attributeId}
          validation={inputValidation}
          width="12rem"
        />

        <CustomSelect
          label="OPERATOR"
          formRegister={register}
          name="operatorId"
          width="4.5rem"
          defaultValue={plusOperator.id}
          options={selectedAttribute?.type === ATTRIBUTES_TYPES.WHOLE_NUMBER ? operators : [equalsOperator]}
          validation={inputValidation}
        />
        {selectedAttribute?.type === ATTRIBUTES_TYPES.WHOLE_NUMBER ? (
          <UncontrolledInput
            labelText="VALUE"
            formRegister={register}
            name="value"
            width="6rem"
            type="number"
            validation={inputValidation}
            defaultValue={action?.value}
            testId="VALUE"
          />
        ) : (
          <CustomSelect
            label="VALUE"
            formRegister={register}
            name="value"
            width="8.5rem"
            displayKey="name"
            options={selectedAttribute.attributeValues ?? []}
            defaultValue={action?.value}
            validation={inputValidation}
          />
        )}
      </FormRow>
      <ButtonsContainer>
        <ActionFormButton
          data-testid="SUBMIT_ACTION"
          type="submit"
          className="btn btn-outline-success shadow border-0"
          disabled={!isDirty || !isValid}
        >
          <i className="fa fa-check" />
        </ActionFormButton>
        <ActionFormButton
          type="button"
          className="btn shadow border-0 btn-outline-primary"
          buttonType="undo"
          onClick={() => reset()}
          disabled={!isDirty}
        >
          <i className="fa fa-undo" />
        </ActionFormButton>
        <ActionFormButton
          type="button"
          className="btn border-0 shadow btn-outline-danger"
          onClick={handleDelete}
          buttonType="delete"
        >
          <i className="fa fa-times" />
        </ActionFormButton>
      </ButtonsContainer>
      <FormRow className="gap-2 mt-3 ps-5">
        {!isMessagingIntegrationAction && formType !== ACTION_TYPES.MAGIC_LINK && (
          <CustomSelect
            label="TRACK PER"
            formRegister={register}
            name="applyStrategy"
            width="7.5rem"
            displayKey="name"
            options={[
              { id: "INSTANCE", name: "Instance" },
              { id: "STATEFUL", name: "State" }
            ]}
            defaultValue={action?.value}
            validation={inputValidation}
          />
        )}
        {applyStrategy !== "STATEFUL" && (
          <CustomSelect
            label="LIMIT 1 PER"
            formRegister={register}
            name="limit"
            width="7.5rem"
            displayKey="name"
            options={[
              { id: "NONE", name: "No Limit" },
              { id: "HOUR", name: "Hour" },
              { id: "DAY", name: "Day" },
              { id: "WEEK", name: "Week" },
              { id: "MONTH", name: "Month" },
              { id: "YEAR", name: "Year" },
              { id: "EVER", name: "All Time" }
            ]}
            defaultValue={action?.value}
            validation={inputValidation}
          />
        )}

        {formType === ACTION_TYPES.MANUAL_ENTRY_PARTICIPANT && (
          <span style={{ paddingBottom: "0.75rem" }}>
            <CustomCheckbox
              formRegister={register}
              name="approvalRequired"
              checked={approvalRequired}
              label="Needs Approval"
              inputStyle={{ display: "flex", justifyContent: "center" }}
              testId="approvalRequired"
            />
          </span>
        )}
      </FormRow>
      <FeatureFlag featureName={FEATURE_FLAGS.EXPIRING_ACTIONS}>
        {applyStrategy !== "STATEFUL" && <ActionExpirationForm emitExpiration={setExpirationValues} />}
      </FeatureFlag>
    </Form>
  );
}

CustomActionForm.propTypes = {
  handleClearForm: PropTypes.func,
  handleDelete: PropTypes.func,
  handleSaveSuccess: PropTypes.func,
  action: PropTypes.object,
  setDirtyForms: PropTypes.func,
  actionId: PropTypes.string
};

CustomActionForm.defaultProps = {
  handleClearForm: () => ({}),
  handleDelete: () => ({}),
  action: {}
};

export default React.memo(CustomActionForm);
