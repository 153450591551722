import React from "react";
import styled from "styled-components";
import { map, isEmpty } from "lodash";
import { Field } from "theme/StyledComponents";

const StyledSelect = styled("select").attrs({
  className: "select form-select form-select-sm",
  ariaLabel: ".form-select-sm example"
})`
  background-color: transparent;
  width: 100%;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
`;

function CustomSelect({
  defaultValue,
  name,
  options = [],
  label,
  formRegister = () => ({}),
  displayKey,
  valueKey,
  validation = {},
  disabled = false,
  tooltips = false,
  ...rest
}) {
  return (
    <Field {...rest}>
      <StyledSelect
        name={name}
        {...formRegister(name, { ...validation })}
        defaultValue={defaultValue}
        data-testid={`CUSTOM_SELECT_${name}`}
        disabled={disabled}
      >
        {map(options, (option) => {
          if (isEmpty(option)) {
            return null;
          }
          return (
            <option
              title={tooltips ? option?.tooltipText : ""}
              key={option?.id}
              value={!!valueKey ? option[valueKey] : option?.id}
            >
              {option[displayKey] || option?.value}
            </option>
          );
        })}
      </StyledSelect>
      <div className="label">{label}</div>
    </Field>
  );
}

export default CustomSelect;
