import { v4 } from "uuid";

export const attributeBoilerPlate = (projectId, type) => ({
  id: v4(),
  type,
  projectId,
  name: "",
  value: "",
  iconName: "",
  iconUrl: "",
  attributeValues: [],
  active: true,
});

export const ATTRIBUTES_TYPES = {
  WHOLE_NUMBER: "WHOLE_NUMBER",
  VALUE_LIST: "VALUE_LIST",
  CONNECTION: "CONNECTION",
};
