import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { UncontrolledInput } from "components/CustomInput/CustomInput";
import CustomImageInput from "components/CustomImage/CustomImageInput";
import { useMutation } from "@apollo/client";
import { ADD_ATTRIBUTE, DELETE_ATTRIBUTE } from "api/attributes";
import { FormErrorText } from "theme/StyledComponents";
import CustomSelect from "components/CustomSelect";
import { useShouldResetForm, useSubmit, useHasDirtyForm } from "hooks/form.hooks";
import messages from "../../api/messages";
import { usePreviousValue } from "hooks/api.hooks"; // REPLACE
import { attributeFromFormData, inputValidation } from "utils/form.utils";
import VerticalGripIcon from "assets/vertical-grip-icon";
import { setGlobalLoading } from "features/Common/CommonSlice";
import AttributeFormActionButtons from "./AttributeFormActionButtons";

const WholeNumberForm = ({
  attribute,
  refetchAttributes,
  shouldShowDeleteModal,
  onSubmitSuccess,
  setDirtyForms,
  parentForm,
  index,
  attributeId
}) => {
  const dispatch = useDispatch();
  const previousAttribute = usePreviousValue(attribute);
  const [saveAttribute, { loading: saving }] = useMutation(ADD_ATTRIBUTE);
  const [deleteAttribute, { loading: deleting }] = useMutation(DELETE_ATTRIBUTE); // TODO(mb) consider passing query & mutation errors to the request handler
  const [iconUrl, setIconUrl] = useState(attribute?.iconUrl ?? null);
  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, errors, dirtyFields, touchedFields },
    setFocus,
    setValue,
    setError,
    clearErrors
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      ...attribute,
      attributeValues: attribute?.attributeValues || [],
      image: iconUrl
    }
  });

  const saveOrUpdateAttribute = useSubmit({
    mutation: saveAttribute,
    successMessage: messages.ATTRIBUTE_SAVE_SUCCESS,
    errorMessage: messages.ATTRIBUTE_SAVE_ERROR,
    dataPath: ADD_ATTRIBUTE.definitions[0].name.value,
    onSuccess: async () => {
      reset(undefined, { keepValues: true });
      await onSubmitSuccess();

      // instead of refetching everything, set this value so we know we have to use the api to delete
      setValue("createdAt", new Date());
      setDirtyForms((prev) => {
        prev.delete(attributeId); // TODO(mb) attribute.id comes from useFieldArray, unsure why some forms are still dirty but deleting both from Set for now on save success
        prev.delete(attribute.id);
        return new Set(prev);
      });
    },
    extractFormData: attributeFromFormData(attributeId, index)
  });

  const submitDelete = useSubmit({
    mutation: deleteAttribute,
    variables: { id: attributeId },
    useVariables: true,
    dataPath: DELETE_ATTRIBUTE.definitions[0].name.value,
    successMessage: messages.ATTRIBUTE_DELETE_SUCCESS,
    errorMessage: messages.ATTRIBUTE_DELETE_ERROR,
    onSuccess: () => {
      deleteLocal(index);
      refetchAttributes();
    }
  });

  const deleteLocal = useCallback((i) => {
    parentForm.remove(i);
  }, []); // eslint-disable-line

  const handleDelete = async () => {
    try {
      if ("createdAt" in attribute) {
        submitDelete();
      } else {
        deleteLocal(index);
      }
    } catch (error) {
      console.error("Error deleting attribute");
    }
  };

  useShouldResetForm(attribute, previousAttribute, reset);

  const handleReset = () => {
    reset({
      ...attribute,
      attributeValues: attribute?.attributeValues || [],
      image: attribute?.iconUrl
    });
    setIconUrl(attribute?.iconUrl ?? null);
  };

  useHasDirtyForm(Object.keys(dirtyFields).length > 0, attributeId, setDirtyForms);

  const inputWidth = "16rem";

  // TODO: Make list dynamic
  const displayOptionList = [
    {
      id: "ATTRIBUTE_LIST",
      name: "Attribute List"
    },
    {
      id: "METRICS_AREA",
      name: "Metrics Area"
    },
    {
      id: "PROFILE",
      name: "Profile"
    },
    {
      id: "NONE",
      name: "None"
    }
  ];

  const defaultDisplayLocation = "Metrics Area";

  useEffect(() => {
    if (saving || deleting) {
      dispatch(setGlobalLoading(true));
    } else {
      dispatch(setGlobalLoading(false));
    }
  }, [saving, deleting, dispatch]);

  const updateImage = (img) => {
    setValue("image", img, { shouldDirty: true, shouldTouch: true });
  };

  return (
    <div
      className="d-flex align-items-center justify-content-start gap-3 flex-wrap w-100"
      style={{ position: "relative", paddingLeft: "4rem" }}
    >
      <div style={{ position: "absolute", left: "1rem", margin: "auto 0" }}>
        <VerticalGripIcon style={{ transform: "scale(2.2)" }} />
      </div>
      <div className="d-flex">
        <CustomImageInput
          className="customImageInput"
          name="image"
          url={iconUrl}
          parentForm={{ register, setFocus, setError, clearErrors }}
          emitImage={updateImage}
          style={{
            width: "75px",
            height: "75px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        />
        <FormErrorText>{errors?.image?.message}</FormErrorText>
      </div>
      <div style={{ width: inputWidth }}>
        <UncontrolledInput
          name="name"
          placeholder="Attribute name"
          labelText="NAME"
          formRegister={register}
          validation={inputValidation}
        />
      </div>
      <div style={{ width: inputWidth }}>
        <UncontrolledInput
          name="value"
          validation={inputValidation}
          placeholder="value"
          labelText="INITIAL VALUE"
          type="number"
          formRegister={register}
        />
      </div>
      <div>
        <CustomSelect
          label="DASHBOARD DISPLAY"
          name="displayLocation"
          options={displayOptionList}
          width="10rem"
          maxWidth="10rem"
          formRegister={register}
          defaultValue={defaultDisplayLocation}
          displayKey="name"
        />
      </div>
      <AttributeFormActionButtons
        dirtyFieldsEmpty={Object.keys(dirtyFields).length === 0}
        isValid={isValid}
        saving={saving}
        deleting={deleting}
        submit={handleSubmit(saveOrUpdateAttribute)}
        reset={handleReset}
        handleDelete={handleDelete}
        shouldShowDeleteModal={shouldShowDeleteModal}
      />
    </div>
  );
};

export default memo(WholeNumberForm);
