import { showAlert } from "features/Common/CommonSlice";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

export const useHandleScrollToTop = (elementId) => {
  const handler = useCallback(() => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }, [elementId]);

  return handler;
};

// Hook that uses a callback to handle clicks outside a passed ref
export const useOutsideClick = (ref, handleOutsideClick) => {
  useEffect(() => {
    // Bind the event listener
    function _handleOutsideClick(event) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !document.getElementsByClassName("modal").length // don't apply outerclick if modal is open
      ) {
        handleOutsideClick();
      }
    }
    document.addEventListener("mousedown", _handleOutsideClick);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", _handleOutsideClick);
    };
  }, [ref, handleOutsideClick]);
};

export const useCopyToClipboard = (text) => {
  const dispatch = useDispatch();

  return useCallback(() => {
    window.navigator.clipboard
      .writeText(text)
      .then(() => {
        dispatch(showAlert({ message: "URL copied to clipboard!" }));
        console.info("Text copied to clipboard: ", text);
      })
      .catch((error) => {
        console.error("Error copying text to clipboard: ", error);
      });
  }, [text, dispatch]);
};
