import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { useSelector } from "react-redux";

// * COMPONENTS
import CustomSelect from "../CustomSelect";
import { StyledCardShadow } from "../../theme/StyledComponents";

// * HOOKS / UTILS / HELPERS
import { filter } from "lodash";
import { selectProjectData } from "../../features/userData/userDataSlice";
import { useFieldArray, useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { uuidRegex } from "../../utils/regex.utils";

// * MUTATIONS
import { GET_CUSTOM_ACTIONS } from "../../api/actions";
import { GET_CUSTOM_ACTION_UPLOAD_MAPPINGS, GET_PREDEFINED_ACTION_MAPS } from "../../api/csv-upload";

// * CSVActionUploadMappingsTable: component handles displaying and validating action selections from a validated action column
const CSVActionUploadMappingsTable = ({
  projectId,
  configId,
  emitActionMaps,
  actionMappedColumn,
  successMessage = false,
  file,
  handleError
}) => {
  const projectData = useSelector(selectProjectData);
  const { data: customActionResponse } = useQuery(GET_CUSTOM_ACTIONS, {
    variables: { projectId },
    fetchPolicy: "no-cache",
    skip: !projectData?.id
  });

  const actions = filter(customActionResponse?.getCustomActions, { applyStrategy: "INSTANCE" }) ?? [];

  const [getCustomActionUploadMappings] = useMutation(GET_CUSTOM_ACTION_UPLOAD_MAPPINGS);
  const [getPredefinedActions] = useMutation(GET_PREDEFINED_ACTION_MAPS);

  const [actionMaps] = useState([]);
  const [formIsValid, setFormIsValid] = useState(false);

  const SELECT_AN_ACTION = "select_an_action";

  // * FORM
  const { register, getValues, control, setValue } = useForm({
    defaultValues: {
      actionMaps
    }
  });

  // * instantiate and link FieldArray fields
  const { fields } = useFieldArray({
    control,
    name: "actionMaps"
  });

  // * test every fieldArray customActionId for valid selection and emit
  const checkValues = () => {
    const base = getValues("actionMaps");
    // * test: are all values selected
    const selectedValuesValid = base.map((val) => val.customActionId !== SELECT_AN_ACTION).every((v) => v === true);

    if (selectedValuesValid) {
      const mapsForParentForm = base.map((actionMapping) => {
        return {
          id: uuidRegex.test(actionMapping.id) ? actionMapping.id : null, // * if the id is an uuid: existing mapping
          fileAction: actionMapping.name,
          customActionId: actionMapping.customActionId === "null" ? null : actionMapping.customActionId
        };
      });
      setFormIsValid(true);
      emitActionMaps(mapsForParentForm);
    } else {
      setFormIsValid(false);
      emitActionMaps([]);
    }
  };

  // * get predefined actions and any customActionUploadMappings from selected action column
  useEffect(() => {
    async function getPredefinedActionsData() {
      const formatRes = await getPredefinedActions({
        variables: {
          projectId,
          column: actionMappedColumn,
          data: {
            file,
            fileCategory: "csv/text"
          }
        }
      });

      const predefinedActions = formatRes?.data?.getPredefinedActionsForSelectedActionColumn?.predefinedActionMaps;
      const predefinedActionsForDisplay = predefinedActions.map((action, index) => {
        return { name: action.name, rowFirstFound: action.rowFirstFound, id: index, customActionId: SELECT_AN_ACTION };
      });
      setValue("actionMaps", predefinedActionsForDisplay);
    }

    async function callGetCustomActionUploadMappings() {
      const formatGetCustomRes = await getCustomActionUploadMappings({
        variables: {
          projectId,
          configId
        }
      });

      const uploadMappings = formatGetCustomRes?.data?.getCustomActionUploadMapsByConfigId?.customActionUploadMappings;

      if (uploadMappings.length > 0) {
        const formMaps = getValues("actionMaps");
        const updatedMapsFromConfig = formMaps.map((action, index) => {
          const found = uploadMappings.find((mapping) => mapping.fileAction === action.name);
          return {
            name: action.name,
            rowFirstFound: action.rowFirstFound,
            id: found?.id ?? index,
            customActionId: found ? (!!found.customActionId ? found.customActionId : "null") : SELECT_AN_ACTION // * if one exists it is set to null (none) else default to "select"
          };
        });

        setValue("actionMaps", updatedMapsFromConfig);
        checkValues();
      }
    }

    if (!!actionMappedColumn && successMessage) {
      setFormIsValid(false);
      getPredefinedActionsData()
        .then(() => {
          if (configId) {
            callGetCustomActionUploadMappings().catch((e) =>
              handleError("ERROR FETCHING CUSTOM ACTION MAPPINGS: " + e.message)
            );
          }
        })
        .catch((e) => {
          handleError("ERROR FETCHING ACTIONS: " + e.message);
        });
    }
  }, [
    actionMappedColumn,
    file,
    getPredefinedActions,
    projectId,
    setValue,
    successMessage,
    getCustomActionUploadMappings,
    configId,
    getValues,
    handleError
  ]);

  return (
    <>
      {!!actionMappedColumn && successMessage && (
        <>
          <div>
            <h4 className="m-auto mt-3">Action Mapping</h4>
            <div style={formIsValid ? { color: "green", ...defaultStyles } : { color: "red", ...defaultStyles }}>
              {formIsValid ? (
                <>
                  <i className="fa fa-check"></i> All actions confirmed
                </>
              ) : (
                "( * Please select a Katilyst action or '(None)' for all fields )"
              )}
            </div>
          </div>
          <div
            className="w-100"
            style={{
              paddingRight: "2rem",
              marginLeft: "1rem",
              paddingLeft: "2rem"
            }}
          >
            <StyledCardShadow className="py-3" style={{ overflowY: "auto", height: "275px" }} onChange={checkValues}>
              <Card className="mb-2 shadow-sm">
                <CardBody>
                  <Row>
                    <Col>
                      <h5>CSV Action Value</h5>
                    </Col>
                    <Col>
                      <h5>First Row Found</h5>
                    </Col>
                    <Col></Col>
                  </Row>
                  {fields.map((row, index) => {
                    return (
                      <Row className="pb-3" key={row.id}>
                        <Col className="d-flex align-items-center justify-content-center">{row.name}</Col>
                        <Col className="d-flex align-items-center justify-content-center">
                          <div style={{ fontSize: "18px" }}>{row.rowFirstFound}</div>
                        </Col>
                        <Col>
                          <CustomSelect
                            formRegister={register}
                            name={`actionMaps.${index}.customActionId`}
                            label="PROJECT ACTION"
                            options={[
                              {
                                name: "Select an action...",
                                id: SELECT_AN_ACTION,
                                rowFirstFound: 1000,
                                customActionId: SELECT_AN_ACTION
                              },
                              ...actions,
                              { name: "(None)", id: "null", rowFirstFound: -1, customActionId: "null" }
                            ]}
                            displayKey="name"
                            maxWidth="100%"
                            width="75%"
                            valueKey="id"
                            // * when index and id match the selected action's customActionId is what gets set on the field
                          />
                        </Col>
                      </Row>
                    );
                  })}
                </CardBody>
              </Card>
            </StyledCardShadow>
          </div>
        </>
      )}
    </>
  );
};

const defaultStyles = { margin: "auto", fontSize: "14px", marginLeft: "50%" };

export default CSVActionUploadMappingsTable;
