import React, { useState, memo } from "react";
import { Row, Collapse, Card } from "reactstrap";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { isEmpty } from "lodash";
import DragDropItem from "../../features/Attributes/DragDropItem";
import { StyledCardHeader } from "features/Attributes/Attributes.style";
import { StyledCircleButton } from "theme/StyledComponents";

const ValueList = ({ handleOnDragEnd, attribute, addItemToList, formUtils, emitImage }) => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  return (
    <Card>
      <StyledCardHeader onClick={toggle}>
        <span className="font-weight-bold" style={{ textAlign: "left", color: "#5a864b" }}>
          <i className={`fa ${!open ? "fa-angle-right" : "fa-angle-down"}`} style={{ marginRight: 10 }} />
          VALUES
        </span>
        <p
          style={{
            marginLeft: 20,
            color: "#5a864b",
            marginBottom: 0,
            textTransform: "uppercase"
          }}
        >
          Initial Value: {formUtils?.fields[0]?.name || attribute?.attributeValues[0]?.name}
        </p>
      </StyledCardHeader>

      <Collapse className="attributesCollapse" style={{ padding: "10px" }} isOpen={open}>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="taskList">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {!isEmpty(formUtils.fields) &&
                  formUtils.fields.map((field, index) => (
                    <DragDropItem
                      key={field.id}
                      task={field}
                      valueListId={attribute.id}
                      index={index}
                      fieldName="name"
                      formUtils={formUtils}
                    />
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Row>
          <StyledCircleButton data-testid="ADD_LIST_ITEM" onClick={addItemToList} className="fa fa-plus mx-auto" />
        </Row>
      </Collapse>
    </Card>
  );
};

export default memo(ValueList);
