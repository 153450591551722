import React from "react";
import { Field } from "theme/StyledComponents";
import { formatDateForDisplay } from "../utils/date.utils";

export function CustomDatePicker({ formRegister, name, label, disabled = false, ...rest }) {
  const date = new Date();
  const now = formatDateForDisplay(date);

  return (
    <Field {...rest}>
      <input
        type="date"
        {...formRegister(name || "date")}
        required
        defaultValue={now}
        pattern="\d{4}-\d{2}-\d{2}"
        disabled={disabled}
        data-testid={`CUSTOM_DATE_PICKER-${name}`}
      />
      <label className="label">{label || "DATE"}</label>
    </Field>
  );
}

export default CustomDatePicker;
