import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@apollo/client";

import { selectUserId } from "app/selectors";
import { SUBMIT_MAGIC_LINK } from "api/actions";
import { useSubmit } from "../form.hooks";
import { useCheckActionLimit } from "hooks/application.hooks";
import { showAlert } from "features/Common/CommonSlice";
import { useQueryParams } from "./useQueryParams";
import { useUpdateQueryParams } from "./useUpdateQueryParams";

export function useMagicLink(handleSuccess = () => ({})) {
  const userId = useSelector(selectUserId);
  const updateQueryParams = useUpdateQueryParams();
  const dispatch = useDispatch();
  const queryParams = useQueryParams();
  const currentUserId = useSelector(selectUserId);
  const actionId = queryParams.get("actionId");
  const checkActionLimit = useCheckActionLimit();
  const [submitMagicLink] = useMutation(SUBMIT_MAGIC_LINK);

  const handleSubmit = useSubmit({
    mutation: submitMagicLink,
    dataPath: SUBMIT_MAGIC_LINK.definitions[0].name.value,
    onSuccess: () => {
      handleSuccess();
      queryParams.delete("actionId");
      updateQueryParams(queryParams, true); // we want to use history.replace so you can navigate backwards
    },
    onFail: () => {
      queryParams.delete("actionId");
      updateQueryParams(queryParams, true); // we want to use history.replace so you can navigate backwards
    },
    useVariables: true,
    variables: {
      data: { customActionId: actionId, userId: currentUserId, actionDate: new Date(), approvalDetails: "" }
    }
  });

  const sendSubmitAction = useCallback(async () => {
    const { success: withinTimeFrame, message } = await checkActionLimit(actionId, userId);
    if (!withinTimeFrame) {
      // delete actionId from params and redirect from state
      queryParams.delete("actionId");
      updateQueryParams(queryParams, true);
      dispatch(showAlert({ type: "danger", message: message ?? "Limit exceeded for that action" }));
    } else {
      handleSubmit();
    }
  }, [handleSubmit, checkActionLimit, actionId, dispatch, queryParams, updateQueryParams, userId]);

  useEffect(() => {
    if (actionId) {
      sendSubmitAction().catch((error) => {
        console.error(error);
      });
    }
  }, [actionId, sendSubmitAction]);
}
