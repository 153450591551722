import React, { memo, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm, useFieldArray } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { UncontrolledInput } from "components/CustomInput/CustomInput";
import CustomImageInput from "components/CustomImage/CustomImageInput";
import { useMutation } from "@apollo/client";
import { ADD_ATTRIBUTE, DELETE_ATTRIBUTE } from "api/attributes";
import { FormErrorText } from "theme/StyledComponents";
import CustomSelect from "components/CustomSelect";
import { useShouldResetForm, useSubmit, useHasDirtyForm } from "hooks/form.hooks";
import messages from "../../api/messages";
import ValueList from "features/Attributes/ValueList";
import { usePreviousValue } from "hooks/api.hooks"; // REPLACE
import { attributeFromFormData, inputValidation } from "utils/form.utils";
import VerticalGripIcon from "assets/vertical-grip-icon";
import { setGlobalLoading } from "features/Common/CommonSlice";
import AttributeFormActionButtons from "./AttributeFormActionButtons";

const ValueListForm = ({
  attribute,
  refetchAttributes,
  shouldShowDeleteModal,
  onSubmitSuccess,
  attributesInUse = [],
  setDirtyForms,
  parentForm,
  index,
  attributeId
}) => {
  const dispatch = useDispatch();
  const previousAttribute = usePreviousValue(attribute);
  const [saveAttribute, { loading: saving }] = useMutation(ADD_ATTRIBUTE);
  const [deleteAttribute, { loading: deleting }] = useMutation(DELETE_ATTRIBUTE); // TODO(mb) consider passing query & mutation errors to the request handler

  const {
    control,
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { isValid, errors, dirtyFields },
    setFocus,
    setValue,
    setError,
    clearErrors
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      ...attribute,
      attributeValues: attribute?.attributeValues || [],
      image: attribute?.iconUrl ?? null
    }
  });

  const { fields, append, remove, move } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "attributeValues" // unique name for your Field Array
  });

  const saveOrUpdateAttribute = useSubmit({
    mutation: saveAttribute,
    successMessage: messages.ATTRIBUTE_SAVE_SUCCESS,
    errorMessage: messages.ATTRIBUTE_SAVE_ERROR,
    dataPath: ADD_ATTRIBUTE.definitions[0].name.value,
    onSuccess: async () => {
      reset(undefined, { keepValues: true });
      await onSubmitSuccess();

      // instead of refetching everything, set this value so we know we have to use the api to delete
      setValue("createdAt", new Date());
      setDirtyForms((prev) => {
        prev.delete(attributeId); // TODO(mb) attribute.id comes from useFieldArray, unsure why some forms are still dirty but deleting both from Set for now on save success
        prev.delete(attribute.id);
        return new Set(prev);
      });
    },
    extractFormData: attributeFromFormData(attributeId, index)
  });

  const submitDelete = useSubmit({
    mutation: deleteAttribute,
    variables: { id: attributeId },
    useVariables: true,
    dataPath: DELETE_ATTRIBUTE.definitions[0].name.value,
    successMessage: messages.ATTRIBUTE_DELETE_SUCCESS,
    errorMessage: messages.ATTRIBUTE_DELETE_ERROR,
    onSuccess: () => {
      deleteLocal(index);
      refetchAttributes();
    }
  });

  const deleteLocal = useCallback((i) => {
    parentForm.remove(i);
  }, []); // eslint-disable-line

  const handleDelete = async () => {
    try {
      if ("createdAt" in attribute) {
        submitDelete();
      } else {
        deleteLocal(index);
      }
    } catch (error) {
      console.error("Error deleting attribute");
    }
  };

  function handleOnDragEnd({ destination, source }) {
    move(source.index, destination.index);
  }

  const addItemToList = () => {
    append({
      id: uuidv4(),
      name: "",
      active: true,
      isNew: true,
      iconName: "",
      iconUrl: ""
    });
  };

  useShouldResetForm(attribute, previousAttribute, reset);

  const handleReset = () => {
    reset();
  };

  const formUtils = {
    remove,
    fields,
    register,
    setValue,
    getValues,
    refetchAttributes,
    attributesInUse,
    setError,
    errors,
    clearErrors
  };

  // * check dirtyFields to set Prompt case since isDirty is true after form inits
  useHasDirtyForm(Object.keys(dirtyFields).length > 0, attributeId, setDirtyForms);

  const inputWidth = "16rem";

  // TODO(MB): Make list dynamic
  const displayOptionList = [
    {
      id: "ATTRIBUTE_LIST",
      name: "Attribute List"
    },
    {
      id: "METRICS_AREA",
      name: "Metrics Area"
    },
    {
      id: "PROFILE",
      name: "Profile"
    },
    {
      id: "NONE",
      name: "None"
    }
  ];

  const defaultDisplayLocation = "Metrics Area";

  useEffect(() => {
    if (saving || deleting) {
      dispatch(setGlobalLoading(true));
    } else {
      dispatch(setGlobalLoading(false));
    }
  }, [saving, deleting, dispatch]);

  const updateImage = (img) => {
    setValue("image", img, { shouldDirty: true, shouldTouch: true });
  };

  return (
    <div
      className="d-flex align-items-center justify-content-start gap-3 flex-wrap w-100"
      style={{ position: "relative", paddingLeft: "4rem" }}
    >
      <div style={{ position: "absolute", left: "1rem", margin: "auto 0" }}>
        <VerticalGripIcon style={{ transform: "scale(2.2)" }} />
      </div>
      <div className="d-flex">
        <CustomImageInput
          className="customImageInput"
          name="image"
          url={attribute.iconUrl}
          emitImage={updateImage}
          parentForm={{ register, setFocus, setError, clearErrors }}
          style={{
            width: "75px",
            height: "75px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        />
        <FormErrorText>{errors?.image?.message}</FormErrorText>
      </div>
      <div style={{ width: inputWidth }}>
        <UncontrolledInput
          name="name"
          placeholder="Attribute name"
          labelText="NAME"
          formRegister={register}
          defaultValue={attribute.name}
          validation={inputValidation}
        />
      </div>

      <div style={{ width: "24rem" }} data-testid="VALUE_LIST_TOGGLE">
        <ValueList
          attribute={attribute}
          addItemToList={addItemToList}
          handleOnDragEnd={handleOnDragEnd}
          formUtils={formUtils}
        />
      </div>
      <div>
        <CustomSelect
          label="DASHBOARD DISPLAY"
          name="displayLocation"
          options={displayOptionList}
          width="10rem"
          maxWidth="10rem"
          formRegister={register}
          defaultValue={defaultDisplayLocation}
          displayKey="name"
        />
      </div>
      <AttributeFormActionButtons
        dirtyFieldsEmpty={Object.keys(dirtyFields).length === 0}
        isValid={isValid}
        saving={saving}
        deleting={deleting}
        submit={handleSubmit(saveOrUpdateAttribute)}
        reset={handleReset}
        handleDelete={handleDelete}
        shouldShowDeleteModal={shouldShowDeleteModal}
      />
    </div>
  );
};

export default memo(ValueListForm);
