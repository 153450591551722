import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import { selectProjectData } from "features/userData/userDataSlice";
import { useHandleListChange, useQueryParams, useUpdateQueryParams, useProjects } from "hooks/application";
import { useProjectListOptions, useSubmenu } from "hooks/application.hooks";
import CustomSelectRadio from "./CustomSelectRadio";

const ProjectSelector = () => {
  const queryParams = useQueryParams();
  const updateQueryParams = useUpdateQueryParams();
  const projectListOptions = useProjectListOptions();

  const { isAdmin } = useSubmenu(); // TODO(MB) get this from somewhere else
  const currentProject = useSelector(selectProjectData);
  const projectIdFromParams = queryParams.get("projectId");

  const { refetch } = useProjects();
  const { register, watch } = useForm({
    defaultValues: { projectId: projectIdFromParams }
  });
  const projectId = watch("projectId");

  useHandleListChange("projectId", projectId);

  const handleSuccess = useCallback(async () => {
    refetch().then((res) => {
      if (res?.data?.getProjects?.length) {
        setTimeout(() => {
          const newProjects = res.data.getProjects;
          queryParams.set("projectId", newProjects[newProjects.length - 1].id); // set current project to newly created
          updateQueryParams(queryParams);
        }, 200);
      }
    });
  }, [refetch]);

  return (
    <CustomSelectRadio
      testId="PROJECT-SELECT-CREATE"
      formRegister={register}
      options={projectListOptions}
      name="projectId"
      displayKey="display"
      label="PROJECT"
      defaultValue={currentProject?.id}
      isAdmin={isAdmin}
      handleSuccess={handleSuccess}
    />
  );
};

export default ProjectSelector;
