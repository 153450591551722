import React from "react";
import styled from "styled-components";
import { Draggable } from "react-beautiful-dnd";
import { Col, Row } from "reactstrap";
import { get } from "lodash";
import SortIcon from "assets/sort-icon.png";
import CustomImageInput from "components/CustomImage/CustomImageInput";
import DeleteRow from "components/DeleteRow/DeleteRow";
import { useMutation } from "@apollo/client";
import { DELETE_ATTRIBUTE_VALUE } from "api/attributes";
import { UncontrolledInput } from "components/CustomInput/CustomInput";
import { useSubmit } from "hooks/form.hooks";
import { inputValidation } from "utils/form.utils";
import { FormErrorText } from "theme/StyledComponents";

const Container = styled.div`
  padding: 10px;
  margin-bottom: 8px;
  background-color: white;
`;

const DragDropItem = ({ task, index, formUtils }) => {
  const [deleteAttributeValue] = useMutation(DELETE_ATTRIBUTE_VALUE);
  const confirmDeleteText = "Are you sure you want to delete this value? It is currently being used by a project rule!";
  const itemId = formUtils.getValues()?.attributeValues?.[index]?.id ?? "";
  const deleteAv = useSubmit({
    mutation: deleteAttributeValue,
    variables: { id: itemId },
    successMessage: "Attribute value successfully deleted",
    errorMessage: "There was an error updating that attribute value",
    onSuccess: formUtils.refetchAttributes,
    useVariables: true,
    dataPath: DELETE_ATTRIBUTE_VALUE.definitions[0].name.value
  });

  // pass this as a prop
  const handleDeleteDragDropItem = async () => {
    if ("isNew" in task) {
      formUtils.remove(index);
      return;
    }

    try {
      await deleteAv();
      formUtils.remove(index);
    } catch (err) {
      console.log("error");
    }
  };

  const changeImage = (img) => {
    formUtils.setValue(`attributeValues.${index}.image`, img, { shouldDirty: true, shouldTouch: true });
  };

  return (
    <Draggable draggableId={task.id} index={index}>
      {(provided) => (
        <Container {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
          <Row
            style={{
              borderBottom: "1px solid #c3c3c3",
              alignItems: "center",
              position: "relative"
            }}
          >
            <Col md={2} xs={2}>
              <img alt="" src={SortIcon} width="35" />
            </Col>
            <Col md={6} xs={8}>
              <UncontrolledInput
                type="text"
                className="form-control"
                defaultValue={task.name}
                placeholder="Enter Name"
                name={`attributeValues.${index}.name`}
                formRegister={formUtils.register}
                validation={inputValidation}
              />
            </Col>
            <Col md={2} xs={2} className="p-0 d-flex align-items-center">
              <CustomImageInput
                parentForm={{
                  register: formUtils.register,
                  setError: formUtils.setError,
                  clearErrors: formUtils.clearErrors
                }}
                emitImage={changeImage}
                name={`attributeValues.${index}.image`}
                width="35"
                height={65}
                attributeType="VALUE_LIST"
                url={task.iconUrl}
              />
            </Col>
            <Col md={2}>
              <DeleteRow
                handleDelete={handleDeleteDragDropItem}
                confirmText={confirmDeleteText}
                shouldUseModal={get(task, "isNew", false) || formUtils.attributesInUse.includes(itemId)}
              />
            </Col>
            <div className="mt-2">
              <FormErrorText>{formUtils?.errors?.attributeValues?.[index]?.image?.message}</FormErrorText>
            </div>
          </Row>
        </Container>
      )}
    </Draggable>
  );
};

export default DragDropItem;
